<div style="overflow:hidden;height: 95vh;" class="row">
  <div *ngFor="let img of gridImages" class="col-1 nopadding">

    <div class="d-flex h-100">
      <div class="mx-auto my-auto">
        <img src="https://cdn.fastenhealth.com/logos/sources/{{img}}" class="rounded img-fluid opacity-20">
      </div>
    </div>
  </div>
</div>

<div class="floating-signup outer-div">
  <div class="inner-div">
    <div class="az-signin-wrapper">
      <div class="az-card-signin">
        <img src="assets/images/banner-transparent-trim.png" alt="fasten" class="az-img-logo mg-b-20">

        <div class="az-signin-header">
          <h2>Let's Get Started!</h2>
          <p>Tell us about your Organization</p>



          <img class="cursor-pointer mx-auto d-block rounded-circle border" height="150px" (click)="openLogoCropper()" [src]="croppedImage || 'assets/images/missing_logo.jpg'" />


          <form (ngSubmit)="signupOrgSubmit()" #orgForm="ngForm">
            <div class="form-group">
              <input [(ngModel)]="newOrg.name" name="name" #name="ngModel" required minlength="2" type="text" class="form-control" placeholder="Organization Name">

              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors?.['required']">
                  Organization Name is required.
                </div>
                <div *ngIf="name.errors?.['minlength']">
                  Organization Name must be at least 2 characters long.
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newOrg.website_uri" name="website_uri" #website_uri="ngModel" required [pattern]="urlPattern" class="form-control" autocapitalize="none" placeholder="Website">
              <div *ngIf="website_uri.invalid && (website_uri.dirty || website_uri.touched)" class="alert alert-danger">
                <div *ngIf="website_uri.errors?.['required']">
                  Website is required.
                </div>
                <div *ngIf="website_uri.errors?.['pattern']">
                  Website must be a valid url starting with https://
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newOrg.privacy_policy_uri" name="privacy_policy_uri" #privacy_policy_uri="ngModel" required [pattern]="urlPattern" class="form-control" placeholder="Privacy Policy Url">
              <div *ngIf="privacy_policy_uri.invalid && (privacy_policy_uri.dirty || privacy_policy_uri.touched)" class="alert alert-danger">
                <div *ngIf="privacy_policy_uri.errors?.['required']">
                  Privacy Policy Url is required.
                </div>
                <div *ngIf="privacy_policy_uri.errors?.['pattern']">
                  Privacy Policy must be a valid url starting with https://
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newOrg.terms_and_conditions_uri" name="terms_and_conditions_uri" #terms_and_conditions_uri="ngModel" [pattern]="urlPattern" class="form-control" placeholder="Terms & Conditions Url">
              <div *ngIf="terms_and_conditions_uri.invalid && (terms_and_conditions_uri.dirty || terms_and_conditions_uri.touched)" class="alert alert-danger">
                <div *ngIf="terms_and_conditions_uri.errors?.['pattern']">
                  Terms & Conditions must be a valid url starting with https://
                </div>
              </div>
            </div><!-- form-group -->

            <div class="form-group">
              <select [(ngModel)]="newOrg.company_size" name="company_size" #company_size="ngModel" required class="form-control">
                <option value="" disabled selected>Company Size</option>
                <option value="1_10">1-10</option>
                <option value="10_100">11-100</option>
                <option value="100_500">101-500</option>
                <option value="500_2000">501-2000</option>
                <option value="2000_5000">2001-5000</option>
                <option value="5000_plus">5000+</option>
              </select>
              <div *ngIf="company_size.invalid && (company_size.dirty || company_size.touched)" class="alert alert-danger">
                <div *ngIf="company_size.errors?.['required']">
                  Company Size is required.
                </div>
              </div>
            </div><!-- form-group -->

            <button [disabled]="!orgForm.form.valid || !croppedImageEvent || loading" type="submit" class="btn btn-az-primary btn-block"
                    ngbTooltip="Logo is required" [disableTooltip]="!!croppedImageEvent" placement="top">
              Save Organization
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>

            <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
              <strong>Error</strong> {{errorMsg}}
            </div>
          </form>

        </div><!-- az-signin-header -->
      </div><!-- az-card-signin -->
    </div><!-- az-signin-wrapper -->

  </div>
</div>



