import { Component } from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fastenhealth';

  showHeader:boolean = false;
  showFooter:boolean = true;

  constructor(
    private router: Router,
    // private toastService: ToastService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {

    // navbar backdrop for mobile only
    const navbarBackdrop = document.createElement('div');
    navbarBackdrop.classList.add('az-navbar-backdrop');
    document.querySelector('body')?.appendChild(navbarBackdrop);

    //determine if we should show the header
    this.router.events.subscribe(event => this.routerEvent(event));
  }

  routerEvent(event: any) {
    if (event instanceof NavigationEnd) {
      //modify header
      if (event.url?.startsWith('/auth') || event.url?.startsWith('/desktop')) {
        this.showHeader = false;
      } else {
        // console.log("NU")
        this.showHeader = true;
      }

      // close all open modals when route change
      this.modalService.dismissAll();
    }
  }
}
