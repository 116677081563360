<div class="modal-header">
  <h4 class="modal-title" id="modal-webhook-title">Invite Team Member</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">

  <div class="alert alert-warning mg-t-10" role="alert">
    <strong>Warning</strong> Fasten Health high discourages email address from free domains such as Gmail due to access to sensitive information.
  </div>

  <form>
    <div class="mb-3">
      <label for="emailAddress">Member Email<span ngbTooltip="required" class="text-danger">*</span></label>
      <div class="input-group">
        <input
          required
          id="emailAddress"
          class="form-control"
          placeholder="team_member@example.com"
          name="emailAddress"
          type="email"
          [(ngModel)]="teamMemberEmail"
          #emailAddressField="ngModel"
        />
      </div>
    </div>

    <div *ngIf="emailAddressField.invalid && (emailAddressField.dirty || emailAddressField.touched)"
         class="alert alert-danger">

      <div *ngIf="emailAddressField.errors?.['required']">
        Email Address is required
      </div>
    </div>

    <div *ngIf="submitOrgInviteError" class="alert alert-danger">
      {{submitOrgInviteError}}
    </div>

  </form>
</div>
<div class="modal-footer">
  <div style="display:block; width:100%">
    <button type="button" class="btn  btn-outline-indigo" [disabled]="submitOrgInviteLoading" (click)="sendOrgTeamInvite()">
      Create
      <span *ngIf="submitOrgInviteLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
  </div>
</div>
