<div class="az-signin-wrapper">
  <div class="az-card-signin">
    <img src="assets/images/banner-transparent-trim.png" alt="fasten" class="az-img-logo">

    <div class="az-signin-header">
      <h2>Reset Password</h2>
      <h4>Please enter your email to receive a password reset link</h4>

      <form (ngSubmit)="onSubmit()" #resetForm="ngForm">
        <div class="form-group">
          <label>Email</label>
          <input type="email" class="form-control" id="email" required [(ngModel)]="email" #emailCtrl="ngModel" name="email" placeholder="Enter your email">

          <div *ngIf="emailCtrl.invalid && (emailCtrl.dirty || emailCtrl.touched)" class="alert alert-danger">
            <div *ngIf="emailCtrl.errors?.['required']">
              Email is required.
            </div>
            <div *ngIf="emailCtrl.errors?.['email']">
              Email must be a valid email address.
            </div>
          </div>
        </div><!-- form-group -->

        <button [disabled]="!resetForm.form.valid || loading" type="submit" class="btn btn-az-primary btn-block">Reset Password</button>
        <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
          <strong>Error</strong> {{errorMsg}}
        </div>

        <div *ngIf="successMsg" class="alert alert-success mt-3" role="alert">
          <strong>Submitted</strong> {{successMsg}}
        </div>
      </form>
    </div><!-- az-signin-header -->

    <div class="az-signin-footer">
      <p>Remembered your password? <a routerLink="/auth/signin">Sign In</a></p>
    </div><!-- az-signin-footer -->
  </div><!-- az-card-signin -->
</div><!-- az-signin-wrapper -->
