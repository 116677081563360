import { Component, OnInit } from '@angular/core';
import {ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-logo-cropper',
  templateUrl: './logo-cropper.component.html',
  styleUrls: ['./logo-cropper.component.scss']
})
export class LogoCropperComponent implements OnInit {

  //image cropper
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedImageEvent: ImageCroppedEvent | null = null;
  submitLogoLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    // event.blob can be used to upload the cropped image
    this.croppedImageEvent = event;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  onSubmit() {
    console.log(this.croppedImage)
    this.activeModal.close(this.croppedImageEvent)
  }
}
