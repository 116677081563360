<div class="mg-t-10 container ">
  <nav class="mg-l-auto nav nav-pills flex-column flex-md-row">
    <a class="nav-link active" data-bs-toggle="tab" href="#">API Clients</a>
<!--    <a class="nav-link" data-bs-toggle="tab" href="#">Logs</a>-->
  </nav>


  <div class="card mg-t-20">
    <h5 class="card-header d-flex justify-content-between align-items-center">
      API Credentials
      <a href="https://docs.connect.fastenhealth.com" target="_blank" class="btn card-link float-right">Learn more about API authentication</a>
    </h5>
  </div>


  <div class="card mg-t-20">
    <h5 class="card-header d-flex justify-content-between align-items-center">
      Credentials
      <button (click)="showOrgCredentialEditorModal('create')" type="button" class="btn btn-outline-indigo float-right">Create Credentials</button>
    </h5>

    <div class="card-body">
      <p>Create a key that unlocks full API access, enabling extensive interaction with your account.</p>

      <div *ngIf="!!newCredential" #credWarning class="alert alert-success mg-t-10" role="alert">
        <button (click)="credWarning.hidden = true" type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <strong>Credential Created!</strong>  Make sure to copy your Private Key now. You won’t be able to see it again!
        <br/>
        <strong class="mg-r-10">Public Id:</strong> <code>{{newCredential.org_credential.id}}</code><br/>
        <strong class="mg-r-10">Private Key:</strong> <code>{{newCredential.private_key}}</code>
      </div>

      <table class="table mg-t-10">
        <thead>
        <tr>
          <th scope="col">Status</th>
          <th scope="col">Public Id</th>
          <th scope="col">Private Key</th>
          <th scope="col">Created</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let cred of orgCredentials[portalConfig.config$.apiMode!]">
          <th scope="row">
            <span [ngClass]="{ 'badge-success': cred.status === 'active', 'badge-danger': cred.status === 'inactive'}" class="badge">{{cred.status}}</span>
          </th>
          <td>{{cred.id}} <i (mousedown)="copyPublicId(cred)" ngbTooltip="Copy to Clipboard" class="mg-l-2 cursor-pointer fas fa-clipboard"></i></td>
          <td><code>{{cred.private_key || 'REDACTED'}}</code></td>
          <td>{{cred.created_at | amDateFormat:'LL'}}</td>
          <td>
            <i (click)="showOrgCredentialEditorModal('edit', cred)" placement="top" ngbTooltip="Edit / Delete" class="fas fa-ellipsis-v pl-2 pr-2 float-right cursor-pointer"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class="card mg-t-20">
    <h5 class="card-header d-flex justify-content-between align-items-center">
      Webhooks
      <button (click)="showOrgWebhookEditorModal('create')" type="button" class="btn btn-outline-indigo float-right">Create Webhook</button>
    </h5>


    <div class="card-body">
      <p>Set up your webhook endpoint to receive events from Fasten Connect or learn more about Webhooks.</p>

      <table class="table mg-t-10">
        <thead>
        <tr>
          <th scope="col">Status</th>
          <th scope="col">Url</th>
          <th scope="col">Events</th>
          <th scope="col">Delivery Logs</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let webhook of orgWebhooks[portalConfig.config$.apiMode!]">
          <th scope="row">
            <span [ngClass]="{ 'badge-success': webhook.status === 'active', 'badge-danger': webhook.status === 'inactive'}" class="badge">{{webhook.status}}</span>
          </th>
          <td>{{webhook.endpoint_uri}}</td>
          <td>
            <span [ngbTooltip]="webhook?.event_types?.join(', ')">
              {{webhook?.event_types?.length}} event(s)
            </span>
          </td>
          <td><a [routerLink]="'/developers/webhooks/' + webhook.id + '/logs'"><i  ngbTooltip="View Logs" class="mg-l-2 cursor-pointer fas fa-list"></i></a> </td>
          <td>
            <i (click)="showOrgWebhookEditorModal('edit', webhook)" placement="top" ngbTooltip="Edit / Delete" class="fas fa-ellipsis-v pl-2 pr-2 float-right cursor-pointer"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>


  </div>

</div>
