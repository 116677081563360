import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ConnectService} from '../../services/connect.service';
import {PortalConfig} from '../../models/portal-config';
import {PortalConfigService} from '../../services/portal-config.service';
import {Organization} from '../../models/fasten/organization';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private connectService: ConnectService,
    public portalConfigService: PortalConfigService,
    private router: Router,
  ) { }

  ngOnInit() {

    this.portalConfigService.ConfigSubject.subscribe((config: PortalConfig) => {
      console.log("Configuration changed:", config)
      if(config.user && config.user.org_id && !config.org){
        console.log("attempt to download org information, and store in config")
        this.connectService.getOrg(config.user.org_id).subscribe((org: Organization )=>{
          console.log("org:", org)
          this.portalConfigService.config = {org: org}
        })
      }
    });

  }

  toggleHeaderMenu(event: Event) {
    event.preventDefault();
    document.querySelector('body')?.classList.toggle('az-header-menu-show');
  }
  closeMenu(e: Event) {
    (e.target as HTMLElement).closest('.dropdown')?.classList.remove('show');
    (e.target as HTMLElement).closest('.dropdown .dropdown-menu')?.classList.remove('show');
  }

  signOut(event: Event) {
    this.authService.Signout().then(r => {
      return this.router.navigate(['/auth/signin'])
    })
  }
}
