<div [hidden]="portalConfigService.config$.apiMode == 'live'" class="bg-danger text-white pd-5">
  <strong>Test Mode Enabled</strong>
  <span class="text-white float-right">You're using test data. You will only be able to access sandbox accounts.</span>
</div>

<div class="az-header">
  <div class="container">
    <div class="az-header-left">
      <a routerLink="/" >
        <img src="assets/images/banner-transparent-trim.png" alt="fasten" class="az-logo az-img-logo">
      </a>
      <a href="#" id="azMenuShow" (click)="toggleHeaderMenu($event)" class="az-header-menu-icon d-lg-none"><span></span></a>
    </div><!-- az-header-left -->
    <div class="az-header-menu">
      <div class="az-header-menu-header">
        <a routerLink="/">
          <img src="assets/images/banner-transparent-trim.png" alt="fasten" class="az-img-logo">
        </a>
        <a href="#" (click)="toggleHeaderMenu($event)" class="close">&times;</a>
      </div><!-- az-header-menu-header -->
      <ul class="nav">

        <li class="nav-item" ngbDropdown [ngClass]="{ 'active': dashboard?.isActive }">
          <a routerLink="/dashboard" routerLinkActive="active" #dashboard="routerLinkActive" class="nav-link"><fa-icon [icon]="['fas', 'table-columns']"></fa-icon>&nbsp; Dashboard</a>
        </li>
        <li class="nav-item" ngbDropdown [ngClass]="{ 'active': developers?.isActive }">
          <a routerLink="/developers" routerLinkActive="active" #developers="routerLinkActive" class="nav-link"><fa-icon [icon]="['fas', 'code']"></fa-icon>&nbsp; Developer</a>
        </li>
        <li class="pd-l-20">
          <div [ngClass]="{'on': portalConfigService.config$.apiMode == 'test'}" (click)="portalConfigService.toggleApiMode()" class="mg-l-10 az-toggle az-toggle-danger"><span></span></div>
        </li>
      </ul>
    </div><!-- az-header-menu -->
    <div class="az-header-right">

      <div class="dropdown az-header-notification" ngbDropdown>
        <a class="new" id="notificationsDropdown" ngbDropdownToggle><i class="far fa-sm fa-bell"></i></a>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="notificationsDropdown">
          <div class="az-dropdown-header mg-b-20 d-sm-none">
            <a class="az-header-arrow" (click)="closeMenu($event)"><i class="icon ion-md-arrow-back"></i></a>
          </div>
          <h6 class="az-notification-title">Notifications</h6>
          <p class="az-notification-text">Background Jobs & Updates</p>
          <div class="dropdown-footer"><a routerLink="/background-jobs">View History</a></div>
        </div><!-- dropdown-menu -->
      </div><!-- az-header-notification -->
      <div class="dropdown az-profile-menu" ngbDropdown>
        <a class="az-img-user cursor-pointer" id="profileDropdown" ngbDropdownToggle>
          <img src="/assets/images/profile-image.svg" alt="">
        </a>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="profileDropdown">
          <div class="az-dropdown-header d-sm-none">
            <a class="az-header-arrow" (click)="closeMenu($event)"><i class="icon ion-md-arrow-back"></i></a>
          </div>
          <div [routerLink]="'/patient-profile'" class="az-header-profile cursor-pointer">
            <div class="az-img-user">
              <img src="{{portalConfigService.config$.org?.logo_uri}}" alt="">
            </div><!-- az-img-user -->
            <h6>{{portalConfigService.config$.user?.name}}</h6>
          </div><!-- az-header-profile -->

          <a routerLink="account/settings"  class="dropdown-item  cursor-pointer"><i style="font-size: medium;" class="fas fa-user-circle"></i> Profile Settings</a>
          <a routerLink="org/settings" class="dropdown-item  cursor-pointer"><i style="font-size: medium;" class="fas fa-hospital"></i> Org Settings</a>
          <a  class="dropdown-item  cursor-pointer"><i style="font-size: medium;" class="fas fa-life-ring"></i> Get Support</a>
          <a (click)="signOut($event)" class="dropdown-item  cursor-pointer"><i style="font-size: medium;" class="fas fa-power-off"></i> Sign Out</a>
        </div><!-- dropdown-menu -->
      </div>
    </div><!-- az-header-right -->
  </div><!-- container -->
</div><!-- az-header -->

