import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PortalConfigService} from '../../services/portal-config.service';
import {LogoCropperComponent} from '../../components/logo-cropper/logo-cropper.component';
import {OrgTeamInviteComponent} from '../../components/org-team-invite/org-team-invite.component';
import {ConnectService} from '../../services/connect.service';
import {OrganizationTeamMember} from '../../models/fasten/organization-team-member';

@Component({
  selector: 'app-org-team',
  templateUrl: './org-team.component.html',
  styleUrls: ['./org-team.component.scss']
})
export class OrgTeamComponent implements OnInit {

  teamMembers: OrganizationTeamMember[] = []

  constructor(
    private connectService: ConnectService,
    private modalService: NgbModal,
    public portalConfig: PortalConfigService,
  ) { }

  ngOnInit(): void {
    this.connectService.getOrgTeam(this.portalConfig.config$.user?.org_id!).subscribe((data) => {
      this.teamMembers = data
    })
  }

  openOrgTeamInviteModal(){
    this.modalService.open(OrgTeamInviteComponent).result.then(
      (result) => {
       console.log(`Closed with: ${result}`)
      },
      (reason) => {
        console.log(`Dismissed`, reason);
      },
    );
  }
}
