<div class="mg-t-20 container ">


  <div class="mg-b-20">
    <div>
      <h2>{{portalConfig.config$?.user?.name}}</h2>
      <p class="az-dashboard-text">{{portalConfig.config$?.user?.email}}</p>
    </div>
  </div>


  <div class="row">
    <div class="col-md-10 col-lg-8 col-xl-6">
      <div class="card mg-t-20">
        <h5 class="card-header d-flex justify-content-between align-items-center">
          Update Password
        </h5>
        <form (ngSubmit)="passChangeSubmit()" #passChangeForm="ngForm" passwordConfirmValidator>
          <div class="card-body">
            <p>Use this form to change the password you use to login to your account</p>

            <div class="form-group">
              <label class="az-content-label tx-11 tx-medium tx-gray-600">Old Password</label>
              <input type="password" name="old_password" #old_password="ngModel" class="form-control" required [(ngModel)]="passChangeUpdate.oldPass">
              <div *ngIf="old_password.invalid && (old_password.dirty || old_password.touched)" class="alert alert-danger">
                <div *ngIf="old_password.errors?.['required']">
                  Your current password is required.
                </div>
              </div>
            </div><!-- form-group -->

            <div class="form-group">
              <label class="az-content-label tx-11 tx-medium tx-gray-600">New Password</label>
              <input class="form-control" name="new_password" #new_password="ngModel" required minlength="8" [pattern]="strong_password_pattern" type="password" [(ngModel)]="passChangeUpdate.newPass">
              <div *ngIf="new_password.invalid && (new_password.dirty || new_password.touched)" class="alert alert-danger">
                <div *ngIf="new_password.errors?.['required']">
                  A new password is required.
                </div>
                <div *ngIf="new_password.errors?.['minlength']">
                  The new password must be at least 8 characters long.
                </div>

                <ng-container *ngIf="new_password.errors?.['pattern']">
                  <div *ngIf="!passChangeUpdate.newPass?.match('^(?=.*[A-Z])')">
                    The new password must contain at least one uppercase letter.
                  </div>
                  <div *ngIf="!passChangeUpdate.newPass?.match('(?=.*[a-z])')">
                    The new password must contain at least one lowercase letter.
                  </div>
                  <div *ngIf="!passChangeUpdate.newPass?.match('(.*[0-9].*)')">
                    The new password must contain at least one digit.
                  </div>
                  <div *ngIf="!passChangeUpdate.newPass?.match('(?=.*[!@#$%^&*])')">
                    The new password must contain at least special character.
                  </div>
                </ng-container>
              </div>
            </div><!-- form-group -->

            <div class="form-group">
              <label class="az-content-label tx-11 tx-medium tx-gray-600">Confirm new Password</label>
              <input class="form-control" name="new_password_confirm" #new_password_confirm="ngModel" required type="password" [(ngModel)]="passChangeUpdate.newPassConfirm">
              <div *ngIf="new_password_confirm.invalid && (new_password_confirm.dirty || new_password_confirm.touched)" class="alert alert-danger">
                <div *ngIf="new_password_confirm.errors?.['password_confirm']">
                  The new passwords do not match.
                </div>
              </div>
            </div><!-- form-group -->

            <button [disabled]="!passChangeForm.form.valid || passChangeLoading" class="btn btn-az-primary btn-block">
              Save New Password
              <span *ngIf="passChangeLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>

            <div *ngIf="passChangeErrorMsg" class="alert alert-danger mt-3" role="alert">
              <strong>Error</strong> {{passChangeErrorMsg}}
            </div>

          </div>
        </form>

      </div>
    </div><!-- col -->


    <div class="col-md-10 col-lg-8 col-xl-6">

      <div class="card mg-t-20">
        <h5 class="card-header d-flex justify-content-between align-items-center">
          Update Email Address
        </h5>
        <form (ngSubmit)="emailChangeSubmit()" #emailChangeForm="ngForm">
          <div class="card-body">
            <p>Use this form to change the email address associated with your account.</p>
            <div class="form-group">
              <label class="az-content-label tx-11 tx-medium tx-gray-600">Current Email Address</label>
              <input type="text" class="form-control" required="required" readonly value="{{emailChangeUpdate.oldEmail}}">
            </div><!-- form-group -->

            <div class="form-group">
              <label class="az-content-label tx-11 tx-medium tx-gray-600">New Email Address</label>
              <input class="form-control" required email minlength="4" type="email" name="email" #email="ngModel" autocapitalize="none" [(ngModel)]="emailChangeUpdate.newEmail">
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors?.['required']">
                  Email is required.
                </div>
                <div *ngIf="email.errors?.['minlength']">
                  Email must be at least 4 characters long.
                </div>
                <div *ngIf="email.errors?.['email']">
                  Email must be a valid email address.
                </div>
              </div>
            </div><!-- form-group -->

            <div class="form-group">
              <label class="az-content-label tx-11 tx-medium tx-gray-600">Verify Password</label>
              <input type="password" class="form-control" required name="password" #password="ngModel" [(ngModel)]="emailChangeUpdate.password">
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                <div *ngIf="password.errors?.['required']">
                  Your current password is required.
                </div>
              </div>
            </div><!-- form-group -->

            <button [disabled]="!emailChangeForm.form.valid || emailChangeLoading" class="btn btn-az-primary btn-block">
              Update Email Address
              <span *ngIf="emailChangeLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </form>
      </div>
    </div><!-- col -->
  </div>

</div>
