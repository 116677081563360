import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class IsAuthenticatedAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {

  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise <boolean> {
    let jwtPayload = await this.authService.GetJWTPayload()

    //check if the user is authenticated, if not, redirect to login
    if (!jwtPayload) {
      return await this.router.navigate(['/auth/signin']);
    } else if(jwtPayload.org_id == '' || jwtPayload.org_id == null){
      console.log("User is not associated with an organization, redirecting to org signup", jwtPayload)
      return await this.router.navigate(['/auth/signup/org']);
    }


    // continue as normal
    return true
  }
}
