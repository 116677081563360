<div class="az-content az-content-dashboard">
  <div class="container">
    <div class="az-content-body">
      <div class="az-dashboard-one-title">
        <div>
          <h2 class="az-dashboard-title">Welcome back!</h2>
        </div>
        <div class="az-content-header-right">
          <div class="media">
            <div class="media-body">
              <label>Last Updated</label>
              <h6>Mar 24, 2024</h6>
            </div><!-- media-body -->
          </div><!-- media -->
          <div class="media">
            <div class="media-body">
              <label>Filters</label>
              <h6>None</h6>
            </div><!-- media-body -->
          </div><!-- media -->

        </div>
      </div><!-- az-dashboard-one-title -->

      <div class="alert alert-warning" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
        <strong>Under Construction!</strong> The Dashboard is still under development. We will notify you once you can start using it.
      </div>

      <div class="row row-sm mg-b-20 mg-lg-b-0">
        <div class="col-lg-5 col-xl-4">
          <div class="row row-sm">
            <div class="col-md-6 col-lg-12 mg-b-20 mg-md-b-0 mg-lg-b-20">

              <div class="row row-sm">
                <div class="col-sm-6">
                  <div class="blurred card card-dashboard-two">
                    <div class="card-header">
                      <h6>33.50% <i class="icon ion-md-trending-up tx-success"></i> <small>18.02%</small></h6>
                      <p>API Requests</p>
                    </div><!-- card-header -->
                    <div class="card-body">
                      <div class="bounce-rate-chart-wrapper">
                        <canvas baseChart [type]="'line'" [datasets]="bounceRateChartData" [labels]="bounceRateChartLabels" [options]="bounceRateChartOptions"></canvas>
                      </div>
                    </div><!-- card-body -->
                  </div><!-- card -->
                </div><!-- col -->
                <div class="col-sm-6 mg-t-20 mg-sm-t-0">
                  <div class="blurred card card-dashboard-two">
                    <div class="card-header">
                      <h6>86 <i class="icon ion-md-trending-down tx-danger"></i> <small>0.86%</small></h6>
                      <p>Webhooks</p>
                    </div><!-- card-header -->
                    <div class="card-body">
                      <div class="users-bar-chart-wrapper">
                        <canvas baseChart [type]="'bar'" [datasets]="usersBarChartData" [labels]="usersBarChartLabels" [options]="usersBarChartOptions"></canvas>
                      </div><!-- chart-wrapper -->
                    </div><!-- card-body -->
                  </div><!-- card -->
                </div><!-- col -->
              </div>

            </div><!-- col -->
            <div class="col-md-6 col-lg-12">

              <div class="blurred card card-dashboard-five">
                <div class="card-header">
                  <h6 class="card-title">Connections</h6>
                  <span class="card-text">Total number of authorized patient connections for this billing period.</span>
                </div><!-- card-header -->
                <div class="card-body row row-sm">
                  <div class="col-6 d-sm-flex align-items-center">
                    <div class="card-chart bg-primary">
                      <span class="peity-bar" data-peity="{&quot;fill&quot;: [&quot;#fff&quot;], &quot;width&quot;: 20, &quot;height&quot;: 20 }" style="display: none;">6,4,7,5,7</span><svg class="peity" height="20" width="20"><rect data-value="6" fill="#fff" x="0.4" y="2.8571428571428577" width="3.2" height="17.142857142857142"></rect><rect data-value="4" fill="#fff" x="4.4" y="8.571428571428573" width="3.1999999999999993" height="11.428571428571427"></rect><rect data-value="7" fill="#fff" x="8.4" y="0" width="3.1999999999999993" height="20"></rect><rect data-value="5" fill="#fff" x="12.4" y="5.7142857142857135" width="3.1999999999999993" height="14.285714285714286"></rect><rect data-value="7" fill="#fff" x="16.4" y="0" width="3.200000000000003" height="20"></rect></svg>
                    </div>
                    <div>
                      <label>Unique</label>
                      <h4>33</h4>
                    </div>
                  </div><!-- col -->
                  <div class="col-6 d-sm-flex align-items-center">
                    <div class="card-chart bg-purple">
                      <span class="peity-bar" data-peity="{&quot;fill&quot;: [&quot;#fff&quot;], &quot;width&quot;: 21, &quot;height&quot;: 20 }" style="display: none;">7,4,5,7,2</span><svg class="peity" height="20" width="21"><rect data-value="7" fill="#fff" x="0.42000000000000004" y="0" width="3.3600000000000003" height="20"></rect><rect data-value="4" fill="#fff" x="4.62" y="8.571428571428573" width="3.3599999999999994" height="11.428571428571427"></rect><rect data-value="5" fill="#fff" x="8.82" y="5.7142857142857135" width="3.3599999999999994" height="14.285714285714286"></rect><rect data-value="7" fill="#fff" x="13.020000000000001" y="0" width="3.3599999999999977" height="20"></rect><rect data-value="2" fill="#fff" x="17.22" y="14.285714285714286" width="3.360000000000003" height="5.7142857142857135"></rect></svg>
                    </div>
                    <div>
                      <label>Refresh</label>
                      <h4>9</h4>
                    </div>
                  </div><!-- col -->
                </div><!-- card-body -->
              </div><!-- card-dashboard-five -->
            </div><!-- col -->
          </div><!-- row -->
        </div><!-- col-lg-3 -->
        <div class="col-lg-7 col-xl-8 mg-t-20 mg-lg-t-0">
          <div class="blurred card card-table-one">
            <h6 class="card-title">Authorized Connections</h6>
            <p class="az-content-text mg-b-20">Recently connected healthcare insitutions.</p>
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="wd-5p">&nbsp;</th>
                  <th class="wd-45p">Portal Name</th>
                  <th>Synced Records</th>
                  <th>Patient Auth ID</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td><img style="width:2em;" src="https://cdn.fastenhealth.com/logos/sources/e16b9952-8885-4905-b2e3-b0f04746ed5c.png"/></td>
                  <td><strong>Epic</strong></td>
                  <td><strong>134</strong> (1.51%)</td>
                  <td>6f3f3770-ad00-...</td>
                  <td>complete</td>
                </tr>
                <tr>
                  <td><img style="width:2em;" src="https://cdn.fastenhealth.com/logos/sources/41b42645-350f-475e-b21b-e7a1276fca4f.png"/></td>
                  <td><strong>eClincalWorks</strong></td>
                  <td><strong>290</strong> (3.30%)</td>
                  <td>ae5e7f40-4e7c-...</td>
                  <td>complete</td>
                </tr>
                <tr>
                  <td><img style="width:2em;" src="https://cdn.fastenhealth.com/logos/sources/a0c9ecff-a470-47b9-aa32-debfa2441005.png"/></td>
                  <td><strong>Medicare</strong></td>
                  <td><strong>250</strong> (3.00%)</td>
                  <td>b3af4ab6-9275-...</td>
                  <td>complete</td>
                </tr>
                <tr>
                  <td><img style="width:2em;" src="https://cdn.fastenhealth.com/logos/sources/e16b9952-8885-4905-b2e3-b0f04746ed5c.png"/></td>
                  <td><strong>Epic</strong></td>
                  <td><strong>216</strong> (2.79%)</td>
                  <td>baccf6e8-f020-...</td>
                  <td>complete</td>
                </tr>
                <tr>
                  <td><img style="width:2em;" src="https://cdn.fastenhealth.com/logos/sources/e16b9952-8885-4905-b2e3-b0f04746ed5c.png"/></td>
                  <td><strong>Epic</strong></td>
                  <td><strong>216</strong> (2.79%)</td>
                  <td>61a193cc-33ed-...</td>
                  <td>error</td>
                </tr>
                <tr>
                  <td><img style="width:2em;" src="https://cdn.fastenhealth.com/logos/sources/5863477c-a178-41fc-b9f4-c881f9e8dbca.png"/></td>
                  <td><strong>United Healthcare</strong></td>
                  <td><strong>197</strong> (2.12%)</td>
                  <td>6a79f333-9408-...</td>
                  <td>complete</td>
                </tr>
                </tbody>
              </table>
            </div><!-- table-responsive -->
          </div><!-- card -->
        </div><!-- col-lg -->

      </div>

    </div><!-- az-content-body -->
  </div>
</div><!-- az-content -->

