<div class="az-signin-wrapper">
  <div class="az-card-signin">
    <img src="assets/images/banner-transparent-trim.png" alt="fasten" class="az-img-logo">

    <div class="az-signin-header">
      <h2>Reset Password</h2>
      <h4>Please enter your new password</h4>

      <form (ngSubmit)="onSubmit()" #resetForm="ngForm">
        <div class="form-group">
          <label>Password</label>
          <input #passwordCtrl="ngModel" required minlength="8" type="password" class="form-control" id="password" [(ngModel)]="password" name="password" placeholder="Enter your new password">
          <div   *ngIf="passwordCtrl.invalid && (passwordCtrl.dirty || passwordCtrl.touched)" class="alert alert-danger">
            <div *ngIf="passwordCtrl.errors?.['required']">
              Password is required.
            </div>
            <div *ngIf="passwordCtrl.errors?.['minlength']">
              Password must be at least 8 characters long.
            </div>
          </div>
        </div>
        <div class="form-group">

          <label>Confirm Password</label>
          <input #passwordConfirmCtrl="ngModel" type="password" class="form-control" id="confirmPassword" required [(ngModel)]="confirmPassword" name="confirmPassword" placeholder="Confirm your new password">

          <div *ngIf="passwordConfirmCtrl.invalid && (passwordConfirmCtrl.dirty || passwordConfirmCtrl.touched)" class="alert alert-danger">
            <div *ngIf="passwordConfirmCtrl.errors?.['required']">
              Confirm password is required.
            </div>
          </div>
        </div><!-- form-group -->

        <button [disabled]="!resetForm.form.valid || loading" type="submit" class="btn btn-az-primary btn-block">Reset Password</button>
        <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
          <strong>Error</strong> {{errorMsg}}
        </div>
      </form>
    </div><!-- az-signin-header -->
    <div class="az-signin-footer"></div>
  </div><!-- az-card-signin -->
</div><!-- az-signin-wrapper -->
