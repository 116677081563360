import { Component, OnInit } from '@angular/core';
import {ConnectService} from '../../services/connect.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PortalConfigService} from '../../services/portal-config.service';
import {ActivatedRoute} from '@angular/router';
import {OrganizationWebhookDelivery} from '../../models/fasten/organization-webhook-delivery';
import {OrganizationWebhook} from '../../models/fasten/organization-webhook';
import {OrganizationCredential} from '../../models/fasten/organization-credential';

@Component({
  selector: 'app-webhook-logs',
  templateUrl: './webhook-logs.component.html',
  styleUrls: ['./webhook-logs.component.scss']
})
export class WebhookLogsComponent implements OnInit {

  showAllDeliveries = false
  webhookDeliveries: OrganizationWebhookDelivery[] = []
  webhook: OrganizationWebhook | null = null
  selectedWebhookDelivery: OrganizationWebhookDelivery | null = null

  constructor(
    private connectService: ConnectService,
    public portalConfig: PortalConfigService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    let webhookId = this.route.snapshot.paramMap.get('webhookId')
    if (!webhookId) {
      return
    }

    this.connectService.getOrgWebhookDeliveries(this.portalConfig.config$.user?.org_id!, webhookId!).subscribe((data) => {
      console.log("Webhook Deliveries", data)
      this.webhookDeliveries = (data.deliveries || []).reverse()
      this.webhook = data.webhook

      if (this.webhookDeliveries.length > 0) {
        this.selectedWebhookDelivery = this.webhookDeliveries[0]
      }

    })
  }

  public copySecretToken(secretToken: string | undefined) {
    if (!secretToken) {
      return
    }
    navigator.clipboard.writeText(secretToken);
  }
}
