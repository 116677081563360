<div style="overflow:hidden;height: 95vh;" class="row">
  <div *ngFor="let img of gridImages" class="col-1 nopadding">

    <div class="d-flex h-100">
      <div class="mx-auto my-auto">
        <img src="https://cdn.fastenhealth.com/logos/sources/{{img}}" class="rounded img-fluid opacity-20">
      </div>
    </div>
  </div>
</div>

<div class="floating-signup outer-div">
  <div class="inner-div">
    <div class="az-signin-wrapper">
      <div class="az-card-signin">
        <img src="assets/images/banner-transparent-trim.png" alt="fasten" class="az-img-logo">

        <div class="az-signin-header">
          <h2>Let's Get Started!</h2>
          <p>Create your Fasten Connect account</p>

          <form (ngSubmit)="signupSubmit()" #userForm="ngForm" passwordConfirmValidator>
            <div class="form-group">
              <input [(ngModel)]="newUser.name" name="name" #name="ngModel" required minlength="2" type="text" class="form-control" placeholder="First Name & Last Name">

              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                <div *ngIf="name.errors?.['required']">
                  Name is required.
                </div>
                <div *ngIf="name.errors?.['minlength']">
                  Name must be at least 2 characters long.
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newUser.email" name="email" #email="ngModel" required email minlength="4" type="email" class="form-control" autocapitalize="none" placeholder="Email Address">
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert alert-danger">
                <div *ngIf="email.errors?.['required']">
                  Email is required.
                </div>
                <div *ngIf="email.errors?.['minlength']">
                  Email must be at least 4 characters long.
                </div>
                <div *ngIf="email.errors?.['email']">
                  Email must be a valid email address.
                </div>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newUser.password" name="password" #password="ngModel" required minlength="8" [pattern]="strong_password_pattern" type="password" class="form-control" placeholder="Password">
              <div *ngIf="password.invalid && (password.dirty || password.touched)" class="alert alert-danger">
                <div *ngIf="password.errors?.['required']">
                  Password is required.
                </div>
                <div *ngIf="password.errors?.['minlength']">
                  Password must be at least 8 characters long.
                </div>

                <ng-container *ngIf="password.errors?.['pattern']">
                  <div *ngIf="!newUser.password?.match('^(?=.*[A-Z])')">
                    Password must contain at least one uppercase letter.
                  </div>
                  <div *ngIf="!newUser.password?.match('(?=.*[a-z])')">
                    Password must contain at least one lowercase letter.
                  </div>
                  <div *ngIf="!newUser.password?.match('(.*[0-9].*)')">
                    Password must contain at least one digit.
                  </div>
                  <div *ngIf="!newUser.password?.match('(?=.*[!@#$%^&*])')">
                    Password must contain at least special character.
                  </div>
                </ng-container>
              </div>
            </div><!-- form-group -->
            <div class="form-group">
              <input [(ngModel)]="newUser.password_confirm" name="password_confirm" #password_confirm="ngModel" required type="password" class="form-control" placeholder="Confirm Password">
              <div *ngIf="userForm.errors?.['password_confirm'] && (password_confirm.dirty || password_confirm.touched)" class="alert alert-danger">
                <div *ngIf="userForm.errors?.['password_confirm']">
                  Password confirmation does not match.
                </div>
              </div>
            </div><!-- form-group -->

            <div class="form-group form-check">
              <input [(ngModel)]="newUser.agree_terms" name="agree_terms" #agree_terms="ngModel" type="checkbox" class="form-check-input" id="agreeTermsCheck" required>
              <label class="form-check-label" for="agreeTermsCheck">
                I have read and agree to the Fasten Connect <br/> <a href="https://policy.fastenhealth.com/connect/privacy_policy.html">Privacy Policy</a>
              </label>

              <div *ngIf="agree_terms.invalid && (agree_terms.dirty || agree_terms.touched)" class="alert alert-danger">
                <div *ngIf="agree_terms.errors?.['required']">
                  You must agree to the Privacy Policy
                </div>
              </div>
            </div>

            <button [disabled]="!userForm.form.valid || loading" type="submit" class="btn btn-az-primary btn-block">
              Create Account
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>

            <div *ngIf="errorMsg" class="alert alert-danger mt-3" role="alert">
              <strong>Error</strong> {{errorMsg}}
            </div>
          </form>

        </div><!-- az-signin-header -->
      </div><!-- az-card-signin -->
    </div><!-- az-signin-wrapper -->

  </div>
</div>



