import { NgModule, CUSTOM_ELEMENTS_SCHEMA }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import { DevelopersComponent } from './pages/developers/developers.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthSigninComponent } from './pages/auth-signin/auth-signin.component';
import { AuthSignupComponent } from './pages/auth-signup/auth-signup.component';
import { AuthSignupOrganizationComponent } from './pages/auth-signup-organization/auth-signup-organization.component';
import { PasswordConfirmValidatorDirective } from './directives/password-confirm-validator.directive';
import {IsAuthenticatedAuthGuard} from './auth-guards/is-authenticated-auth-guard';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {AuthInterceptorService} from './services/auth-interceptor.service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LogoCropperComponent } from './components/logo-cropper/logo-cropper.component';
import { NgChartsModule } from 'ng2-charts';
import {MomentModule} from 'ngx-moment';
import { OrgSettingsComponent } from './pages/org-settings/org-settings.component';
import { AccountSettingsComponent } from './pages/account-settings/account-settings.component';
import { OrgCredentialsEditorComponent } from './components/org-credentials-editor/org-credentials-editor.component';
import { OrgWebhooksEditorComponent } from './components/org-webhooks-editor/org-webhooks-editor.component';
import { WebhookLogsComponent } from './pages/webhook-logs/webhook-logs.component';
import { OrgBillingComponent } from './pages/org-billing/org-billing.component';
import { OrgTeamComponent } from './pages/org-team/org-team.component';
import { OrgTeamInviteComponent } from './components/org-team-invite/org-team-invite.component';
import { AuthResetRequestComponent } from './pages/auth-reset-request/auth-reset-request.component';
import { AuthResetComponent } from './pages/auth-reset/auth-reset.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DevelopersComponent,
    DashboardComponent,
    AuthSigninComponent,
    AuthSignupComponent,
    AuthSignupOrganizationComponent,
    LogoCropperComponent,
    OrgSettingsComponent,
    AccountSettingsComponent,
    OrgCredentialsEditorComponent,
    OrgWebhooksEditorComponent,
    WebhookLogsComponent,
    OrgBillingComponent,
    OrgTeamComponent,
    OrgTeamInviteComponent,
    AuthResetRequestComponent,
    AuthResetComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PasswordConfirmValidatorDirective,

    NgbModule,
    NgChartsModule,
    InfiniteScrollModule,
    FontAwesomeModule,
    ImageCropperModule,
    MomentModule,
    HighlightModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
      deps: [AuthService, Router]
    },
    IsAuthenticatedAuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      }
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}
