<div class="mg-t-10 container mg-b-35">
  <nav class="mg-l-auto nav nav-pills flex-column flex-md-row">
    <a class="nav-link" data-bs-toggle="tab" [routerLink]="'developers/'" >API Clients</a>
    <a class="nav-link active" data-bs-toggle="tab" href="#">Logs</a>
  </nav>

  <div class="card mg-t-20">
    <h5 class="card-header d-flex justify-content-between align-items-center">
      {{webhook?.endpoint_uri}}
<!--      <i placement="top" ngbTooltip="Edit / Delete" class="fas fa-ellipsis-v pl-2 pr-2 float-right cursor-pointer"></i>-->
    </h5>

    <div class="card-body">

      <div class="table-responsive">
        <table class="table mg-b-0">
          <thead>
          <tr>
            <th>Status</th>
            <th>API Mode</th>
            <th>Listening For</th>
            <th>Signing Secret</th>
            <th>Last Updated</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row">
              <span [ngClass]="{ 'badge-success': webhook?.status === 'active', 'badge-danger': webhook?.status === 'inactive'}" class="badge">{{webhook?.status}}</span>
            </th>
            <th scope="row">
              {{webhook?.api_mode}}
            </th>
            <td>
              <code [ngbTooltip]="webhook?.event_types?.join(', ')">{{webhook?.event_types?.length}} event(s)</code>
            </td>
            <td>
              <a #revealBtn (click)="revealBtn.hidden = true; secret.hidden=false" class="cursor-pointer">reveal <i class="fas fa-lock"></i></a>
              <span hidden #secret>{{webhook?.secret_token}} <i (mousedown)="copySecretToken(webhook?.secret_token)" ngbTooltip="Copy to Clipboard" class="mg-l-2 cursor-pointer fas fa-clipboard"></i></span>
            </td>
            <td>{{webhook?.updated_at | amDateFormat:'LL'}}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>

  <div class="card mg-t-20">
    <h5 class="card-header d-flex justify-content-between align-items-center">
      Delivery Logs
    </h5>
    <div class="card-body">
      <div class="container">
        <div class="row">
          <div class="col-6 bd-r">
            <div class="list-group">
              <a *ngFor="let delivery of (showAllDeliveries ? webhookDeliveries : (webhookDeliveries|slice:0:15))" [ngClass]="{'active': selectedWebhookDelivery?.timestamp_event_id == delivery?.timestamp_event_id}" (click)="selectedWebhookDelivery = delivery" class="cursor-pointer list-group-item list-group-item-action bd-x-0 bd-y-0">
                <span [ngClass]="{ 'badge-success': delivery?.status === 'success', 'badge-danger': delivery?.status === 'failure'}" class="badge mg-r-2 ">
                  <i [ngClass]="{ 'fa-check': delivery?.status === 'success', 'fa-times': delivery?.status === 'failure'}" class="fas"></i>
                </span>

                {{delivery.event_payload.type}} <span [ngbTooltip]="delivery?.created_at | amDateFormat:'YYYY-MM-DD HH:mm'"  class="float-right">{{delivery?.created_at | amTimeAgo}}</span>
              </a>
              <div *ngIf="webhookDeliveries.length == 0">
                <div class="alert alert-info mg-t-10" role="alert">
                  No webhook deliveries yet.
                </div>
              </div>
            </div>
            <button class="btn btn-outline-indigo btn-block" *ngIf="(webhookDeliveries?.length || 0) > 15" (click)="showAllDeliveries = !showAllDeliveries">{{ showAllDeliveries ? 'Show Less' : 'Show More' }}</button>
          </div>
          <div class="col-6">

            <div>
              <strong>Response</strong>
              <pre *ngIf="!!selectedWebhookDelivery; else waitingResponse"><code [languages]="['https']" [highlight]="(selectedWebhookDelivery?.http_response || '')"></code></pre>

            </div>
            <hr/>
            <div>
              <strong>Request</strong>
              <pre *ngIf="!!selectedWebhookDelivery; else waitingResponse"><code [languages]="['json']" [highlight]="selectedWebhookDelivery?.event_payload | json"></code></pre>
            </div>
            <ng-template #waitingResponse>
              <div class="alert alert-info mg-t-10" role="alert">
                Waiting for delivery...
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
