<div class="modal-header">
  <h4 class="modal-title" id="modal-webhook-title">{{editorMode == 'create' ? 'Create' : 'Update' }} Webhook</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Webhooks allow Fasten Connect to notify you once patient medical records have been retrieved and are ready for download.
  </p>
  <p>

  </p>

  <div *ngIf="apiMode! == 'test'" class="alert alert-warning mg-t-10" role="alert">
    <strong>Testing</strong> If you don't have an environment to receive webhooks, you can use <a target="_blank" href="https://public.requestbin.com/r">requestbin.com</a> to test.
  </div>

  <form>
    <div class="mb-3">
      <label for="endpointUri">Endpoint Url<span ngbTooltip="required" class="text-danger">*</span></label>
      <div class="input-group">
        <input
          required
          id="endpointUri"
          class="form-control"
          placeholder="https://example.com/..."
          name="endpointUri"
          type="url"
          [pattern]="urlPattern"
          [(ngModel)]="endpointUri"
          #endpointUriField="ngModel"
        />
      </div>
    </div>
    <div *ngIf="endpointUriField.invalid && (endpointUriField.dirty || endpointUriField.touched)"
         class="alert alert-danger">

      <div *ngIf="endpointUriField.errors?.['required']">
        Endpoint Url is required
      </div>
      <div *ngIf="endpointUriField.errors?.['pattern']">
        Endpoint Url must be a valid url starting with https://
      </div>
    </div>

    <div class="mb-3">
      <span>Webhook Status</span>
      <div class="az-toggle" [ngClass]="{'on': active}"><label for="webhook-active"><span></span></label></div>
      <input
        id="webhook-active"
        class=""
        name="webhook-active"
        type="checkbox"
        [(ngModel)]="active"
        style="display: none"
        #webhookActiveField="ngModel"
      />
    </div>

    <div *ngIf="submitOrgWebhookError" class="alert alert-danger">
      {{submitOrgWebhookError}}
    </div>

  </form>
</div>
<div class="modal-footer">
  <div style="display:block; width:100%">
    <ng-container *ngIf="editorMode == 'edit'">
      <button type="button" class="float-left btn btn-outline-danger" (click)="deleteOrgWebhook()">
        Delete
        <span *ngIf="deleteOrgWebhookLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>

      <button type="button" class="float-right btn btn-outline-indigo" [disabled]="(!endpointUriField.dirty && !webhookActiveField.dirty) || submitOrgWebhookLoading" (click)="updateOrgWebhook()">
        Update
        <span *ngIf="submitOrgWebhookLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </ng-container>

    <ng-container *ngIf="editorMode == 'create'">
      <button type="button" class="btn  btn-outline-indigo" [disabled]="submitOrgWebhookLoading" (click)="createOrgWebhook()">
        Create
        <span *ngIf="submitOrgWebhookLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </ng-container>
  </div>
</div>
