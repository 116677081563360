import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PortalConfig, portalConfigDefaults} from '../models/portal-config';
import {merge} from 'lodash';
import {ApiMode} from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class PortalConfigService {
  // Private
  public ConfigSubject: BehaviorSubject<PortalConfig>;
  private _defaultConfig: PortalConfig;
  constructor() {
    this._defaultConfig = portalConfigDefaults
    this.ConfigSubject = new BehaviorSubject(this._defaultConfig);
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for config
   */
  set config(value: PortalConfig) {
    // get the current config, merge the new values, and then submit.
    const mergedSettings = merge({}, this.ConfigSubject.getValue(), value);

    // Store the value
    if(JSON.stringify(mergedSettings) !== JSON.stringify(this.ConfigSubject.getValue())){
      console.log('updating settings:', mergedSettings)
      this.ConfigSubject.next(mergedSettings);
    }
  }

  get config$(): PortalConfig {
    console.log('using cached settings:', this.ConfigSubject.getValue())
    return this.ConfigSubject.getValue()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resets the config to the default
   */
  reset(): void {
    // Set the config
    this.config = this._defaultConfig
  }

  toggleApiMode(): void {
    this.config = {
      apiMode: this.config$.apiMode === 'live' ? ApiMode.Test : ApiMode.Live
    }
  }
}
