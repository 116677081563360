export class User {
  id?: string
  name?: string
  email?: string
  password?: string
  org_id?: string

  //not sent to the server
  password_confirm?: string = ""
  agree_terms?: boolean = false
}
