import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseWrapper } from '../models/response-wrapper';
import { environment } from '../../environments/environment';
import { Organization } from '../models/fasten/organization';
import { map, Observable } from 'rxjs';
import { ApiMode, CRUDMode } from '../app.constants';
import { OrganizationCredential } from '../models/fasten/organization-credential';
import { OrganizationWebhook } from '../models/fasten/organization-webhook';
import { OrganizationWebhookDelivery } from '../models/fasten/organization-webhook-delivery';
import { OrganizationTeamMember } from '../models/fasten/organization-team-member';
import { User } from "../models/fasten/user";

@Injectable({
  providedIn: 'root'
})
export class ConnectService {

  constructor(private _httpClient: HttpClient) { }



  public updateUser(oldPass: string, oldEmail: string, newEmail: string, newPass: string = ""): Observable<User> {
    const userUpdateJson = JSON.stringify({
      email: oldEmail,
      password: oldPass,
      newEmail: newEmail,
      newPassword: newPass
    });
    return this._httpClient.put<ResponseWrapper>(`${environment.connect_api_endpoint_base}/user`, userUpdateJson)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Updated User", response)

          return response.data as User;
        })
      );
  }

  /**
   * Create a new organization and associate it with the current User
   * @param newOrg
   * @param logoBlob
   * @constructor
   */
  public createOrg(newOrg: Organization, logoBlob: Blob): Observable<ResponseWrapper> {
    const formData = new FormData();

    //add the org data to the form data
    const orgJson = JSON.stringify(newOrg);
    const orgJsonBlob = new Blob([orgJson], {
      type: 'application/json'
    });
    formData.append("org", orgJsonBlob);
    formData.append("logo", logoBlob);

    let resp = this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org`, formData)
    console.log(resp)
    return resp
  }


  public updateOrg(orgId: string, updateOrg: Organization, logoBlob?: Blob | null): Observable<Organization> {
    const formData = new FormData();

    //add the org data to the form data
    const orgJson = JSON.stringify(updateOrg);
    const orgJsonBlob = new Blob([orgJson], {
      type: 'application/json'
    });
    formData.append("org", orgJsonBlob);
    if (logoBlob) {
      formData.append("logo", logoBlob);
    }
    return this._httpClient.put<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}`, formData)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Updated Organization", response)
          return response.data as Organization;
        })
      );
  }

  public getOrg(orgId: String): Observable<Organization> {
    return this._httpClient.get<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}`)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization", response)
          return response.data as Organization;
        })
      );
  }

  public sendOrgInvite(orgId: string, recipientEmail: string): Observable<ResponseWrapper> {
    return this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/invite`, {
      recipient_email_address: recipientEmail,
      org_id: orgId
    })
  }

  public getOrgTeam(orgId: string): Observable<OrganizationTeamMember[]> {
    return this._httpClient.get<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/team`)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization Team", response)
          return response.data as OrganizationTeamMember[];
        })
      );
  }

  public createOrgCredentials(orgId: string, apiMode: ApiMode, redirectUrl: string, note: string): Observable<{ action: CRUDMode, org_credential: OrganizationCredential, private_key: string }> {
    return this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/credentials`, {
      api_mode: apiMode,
      redirect_uri: redirectUrl,
      note: note
    })
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization Credential", response)
          response.data.action = CRUDMode.Create
          return response.data as { action: CRUDMode, org_credential: OrganizationCredential, private_key: string };
        })
      );
  }
  public updateOrgCredential(orgId: string, orgCredentialId: string, redirectUrl: string, note: string): Observable<{ action: CRUDMode, org_credential: OrganizationCredential }> {
    return this._httpClient.put<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/credentials/${orgCredentialId}`, {
      redirect_uri: redirectUrl,
      note: note
    })
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization Credential", response)
          return { action: CRUDMode.Update, org_credential: response.data } as { action: CRUDMode, org_credential: OrganizationCredential };
        })
      );
  }

  public deleteOrgCredential(orgId: string, orgCredentialId: string): Observable<{ action: CRUDMode, org_credential: OrganizationCredential }> {
    return this._httpClient.delete<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/credentials/${orgCredentialId}`)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Deleted Organization Credential", response)
          return { action: CRUDMode.Delete, org_credential: response.data } as { action: CRUDMode, org_credential: OrganizationCredential };
        })
      );
  }

  public getOrgCredentials(orgId: string): Observable<OrganizationCredential[]> {
    return this._httpClient.get<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/credentials`)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization Credentials", response)
          return response.data as OrganizationCredential[];
        })
      );
  }


  public createOrgWebhooks(orgId: string, apiMode: ApiMode, endpointUri: string, status: string): Observable<{ action: CRUDMode, org_webhook: OrganizationWebhook }> {
    return this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/webhooks`, {
      api_mode: apiMode,
      endpoint_uri: endpointUri,
      status: status
    })
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization Credential", response)
          return { action: CRUDMode.Create, org_webhook: response.data };
        })
      );
  }

  public deleteOrgWebhook(orgId: string, orgWebhookId: string): Observable<{ action: CRUDMode, org_webhook: OrganizationWebhook }> {
    return this._httpClient.delete<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/webhooks/${orgWebhookId}`)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Deleted Organization Webhook", response)
          return { action: CRUDMode.Delete, org_webhook: response.data };
        })
      );
  }

  public updateOrgWebhook(orgId: string, orgWebhookId: string, endpointUri: string, status: string): Observable<{ action: CRUDMode, org_webhook: OrganizationWebhook }> {
    return this._httpClient.put<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/webhooks/${orgWebhookId}`, {
      endpoint_uri: endpointUri,
      status: status
    })
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization Webhook", response)
          return { action: CRUDMode.Update, org_webhook: response.data };
        })
      );
  }

  public getOrgWebhooks(orgId: string): Observable<OrganizationWebhook[]> {
    return this._httpClient.get<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/webhooks`)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Organization Webhooks", response)
          return response.data as OrganizationWebhook[];
        })
      );
  }

  public getOrgWebhookDeliveries(orgId: string, orgWebhookId: string): Observable<{ webhook: OrganizationWebhook, deliveries: OrganizationWebhookDelivery[] }> {
    return this._httpClient.get<ResponseWrapper>(`${environment.connect_api_endpoint_base}/org/${orgId}/webhooks/${orgWebhookId}/deliveries`)
      .pipe(
        map((response: ResponseWrapper) => {
          console.log("Webhooks Deliveries", response)
          return response.data as { webhook: OrganizationWebhook, deliveries: OrganizationWebhookDelivery[] };
        })
      );
  }
}
