import { Component, OnInit } from '@angular/core';
import {PortalConfigService} from '../../services/portal-config.service';
import {ConnectService} from '../../services/connect.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-org-billing',
  templateUrl: './org-billing.component.html',
  styleUrls: ['./org-billing.component.scss']
})
export class OrgBillingComponent implements OnInit {


  stripe_customer_portal = environment.stripe_customer_portal;
  loading = false
  recentlyChanged = false

  constructor(
    public portalConfig: PortalConfigService,
    public connectService: ConnectService,
  ) { }

  ngOnInit(): void {
    //read query string parameters
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if(sessionId){
      this.recentlyChanged = true
    }
  }

}
