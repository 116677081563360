<div class="mg-t-10 container ">
  <nav class="mg-l-auto nav nav-pills flex-column flex-md-row">
    <a class="nav-link" data-bs-toggle="tab" routerLink="/org/settings">Details</a>
    <a class="nav-link" data-bs-toggle="tab" routerLink="/org/team">Team</a>
    <a class="nav-link active" data-bs-toggle="tab"  routerLink="/org/billing">Billing</a>
  </nav>

  <ng-template #activePlan>
    <div  class="row">
      <div class="col-12">
        <div class="card mg-t-20">
          <h5 class="card-header d-flex justify-content-between align-items-center">
            Invoices & Payment Methods
            <a [href]="stripe_customer_portal" class="btn btn-link float-right">Visit the Billing Portal</a>
          </h5>
          <div class="card-body">
            <p>Your account is active. You can view invoices and payment methods in the billing portal.</p>
          </div>
        </div>
      </div><!-- col -->
    </div>
  </ng-template>
  <div *ngIf="portalConfig.config$.org?.plan != 'paid'; else activePlan" class="row">
    <div class="col-12">

      <div class="card mg-t-20">
        <h5 class="card-header d-flex justify-content-between align-items-center">
          Inactive Account
        </h5>

        <div class="card-body">
          <div class="row">
            <div class="col-12 tx-gray-700">
              <h5>No plan selected</h5>
              <p>
                Please contact <a href="mailto:support@fastenhealth.com">support@fastenhealth.com</a> to activate your account. <br/>
                Inactive accounts may be purged after 30 days.
              </p>
            </div>
          </div>

        </div>

      </div>
      <div *ngIf="recentlyChanged" class="alert alert-info mg-b-30" role="alert">
        <strong>Plan Changed!</strong> Your plan changed recently. It may take up to 24 hours to reflect plan changes in your organization profile.
      </div>

    </div>
  </div>


</div>
