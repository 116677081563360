/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
import {ApiMode} from '../app.constants';
import {Organization} from './fasten/organization';
import {User} from './fasten/user';

export interface PortalConfig {
  apiMode?: ApiMode;
  org?: Organization;
  user?: User
}

/**
 * Default configuration for the entire application. This object is used by
 * "PortalConfigService" to set the default configuration.
 */
export const portalConfigDefaults: PortalConfig = {
  apiMode: ApiMode.Test
};
