<div class="mg-t-10 container ">
  <nav class="mg-l-auto nav nav-pills flex-column flex-md-row">
    <a class="nav-link active" data-bs-toggle="tab" routerLink="/org/settings">Details</a>
    <a class="nav-link" data-bs-toggle="tab" routerLink="/org/team">Team</a>
    <a class="nav-link" data-bs-toggle="tab"  routerLink="/org/billing">Billing</a>
  </nav>


  <div class="row">
    <div class="col-12">
      <div class="card mg-t-20">
        <h5 class="card-header d-flex justify-content-between align-items-center">
          Details
        </h5>

        <div class="card-body">
          <form (ngSubmit)="updateOrgSubmit()" #orgForm="ngForm">

            <p>Use this form to update information about your Organization</p>


            <div class="row">



              <div class="col-6">

                <div class="form-group">
                  <label class="az-content-label tx-11 tx-medium tx-gray-600">Organization ID</label>
                  <pre><code>{{updateOrg.id}}</code></pre>
                </div><!-- form-group -->

                <div class="form-group">
                  <label class="az-content-label tx-11 tx-medium tx-gray-600">Organization Name</label>
                  <input [(ngModel)]="updateOrg.name" name="name" #name="ngModel" required minlength="2" type="text" class="form-control">

                  <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                    <div *ngIf="name.errors?.['required']">
                      Organization Name is required.
                    </div>
                    <div *ngIf="name.errors?.['minlength']">
                      Organization Name must be at least 2 characters long.
                    </div>
                  </div>
                </div><!-- form-group -->

                <div class="form-group">
                  <label class="az-content-label tx-11 tx-medium tx-gray-600">Organization Logo</label>
                  <br/>
                  <div class="">
                    <img class="cursor-pointer mx-auto d-block rounded-circle border" height="150px" (click)="openLogoCropper()" [src]="croppedImage || updateOrg.logo_uri || 'assets/images/missing_logo.jpg'" alt="">
                  </div><!-- az-img-user -->
                </div><!-- form-group -->
              </div>
              <div class="col-6">

                <div class="form-group">
                  <label class="az-content-label tx-11 tx-medium tx-gray-600">Organization Website</label>
                  <input [(ngModel)]="updateOrg.website_uri" name="website_uri" #website_uri="ngModel" required [pattern]="urlPattern" class="form-control" autocapitalize="none">
                  <div *ngIf="website_uri.invalid && (website_uri.dirty || website_uri.touched)" class="alert alert-danger">
                    <div *ngIf="website_uri.errors?.['required']">
                      Website is required.
                    </div>
                    <div *ngIf="website_uri.errors?.['pattern']">
                      Website must be a valid url starting with https://
                    </div>
                  </div>
                </div><!-- form-group -->

                <div class="form-group">
                  <label class="az-content-label tx-11 tx-medium tx-gray-600">Privacy Policy Url</label>
                  <input [(ngModel)]="updateOrg.privacy_policy_uri" name="privacy_policy_uri" #privacy_policy_uri="ngModel" required [pattern]="urlPattern" class="form-control">
                  <div *ngIf="privacy_policy_uri.invalid && (privacy_policy_uri.dirty || privacy_policy_uri.touched)" class="alert alert-danger">
                    <div *ngIf="privacy_policy_uri.errors?.['required']">
                      Privacy Policy Url is required.
                    </div>
                    <div *ngIf="privacy_policy_uri.errors?.['pattern']">
                      Privacy Policy must be a valid url starting with https://
                    </div>
                  </div>
                </div><!-- form-group -->

                <div class="form-group">
                  <label class="az-content-label tx-11 tx-medium tx-gray-600">Terms & Conditions Url</label>
                  <input [(ngModel)]="updateOrg.terms_and_conditions_uri" name="terms_and_conditions_uri" #terms_and_conditions_uri="ngModel" [pattern]="urlPattern" class="form-control">
                  <div *ngIf="terms_and_conditions_uri.invalid && (terms_and_conditions_uri.dirty || terms_and_conditions_uri.touched)" class="alert alert-danger">
                    <div *ngIf="terms_and_conditions_uri.errors?.['pattern']">
                      Terms & Conditions must be a valid url starting with https://
                    </div>
                  </div>
                </div><!-- form-group -->
              </div>


            </div>

            <button [disabled]="!(orgForm.dirty && orgForm.form.valid) || loading" type="submit" class="btn btn-az-primary float-right">
              Save Changes
              <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>

            <div *ngIf="errorMsg" class="alert alert-danger mt-5" role="alert">
              <strong>Error</strong> {{errorMsg}}
            </div>

          </form>
        </div>

      </div>
    </div><!-- col -->


  </div>

</div>

  <!--<div class="az-content az-content-profile">-->
<!--  <div class="container mn-ht-100p">-->
<!--    <div class="az-content-left az-content-left-profile">-->

<!--      <div class="az-profile-overview">-->
<!--        <div class="az-img-user">-->
<!--          <img src="{{portalConfig.config$.org?.logo_uri}}" alt="">-->
<!--        </div>&lt;!&ndash; az-img-user &ndash;&gt;-->
<!--        <div class="d-flex justify-content-between mg-b-20">-->
<!--          <div>-->
<!--            <h5 class="az-profile-name">{{portalConfig.config$.org?.name}}</h5>-->
<!--            <p class="az-profile-name-text">{{portalConfig.config$.org?.plan}}</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="">-->
<!--          <ul>-->
<!--            <li>-->
<!--              <a [href]="portalConfig.config$.org?.privacy_policy_uri" target="_blank" class="az-link">Privacy Policy</a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a [href]="portalConfig.config$.org?.terms_and_conditions_uri" target="_blank" class="az-link">Terms of Use</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->

<!--        <hr class="mg-y-30">-->

<!--      </div>&lt;!&ndash; az-profile-overview &ndash;&gt;-->

<!--    </div>&lt;!&ndash; az-content-left &ndash;&gt;-->
<!--    <div class="az-content-body az-content-body-profile">-->
<!--      <div class="az-profile-body">-->

<!--        <div class="row mg-b-20">-->

<!--          <div class="col-12">-->
<!--            <div class="card mg-t-20">-->
<!--              <h5 class="card-header d-flex justify-content-between align-items-center">-->
<!--                Invoices & Payment Methods-->
<!--                <a [href]="stripe_customer_portal" class="btn btn-link float-right">Please visit the Billing Portal</a>-->
<!--              </h5>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

<!--        <div class="row mg-b-20">-->

<!--          <div class="col-12">-->
<!--            <div class="mg-t-10">-->
<!--              <div *ngIf="recentlyChanged" class="alert alert-info mg-b-30" role="alert">-->
<!--                <button type="button" class="close" data-dismiss="alert" aria-label="Close">-->
<!--                  <span aria-hidden="true">×</span>-->
<!--                </button>-->
<!--                <strong>Plan Changed!</strong> Your plan changed recently. It may take up to 24 hours to reflect plan changes in your organization profile.-->
<!--              </div>-->


<!--              <div class="card-deck">-->
<!--                <div class="card">-->
<!--                  <img src="..." class="card-img-top" alt="...">-->
<!--                  <div class="card-body">-->
<!--                    <h5 class="card-title">Card title</h5>-->
<!--                    <p class="card-text">This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>-->
<!--                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <img src="..." class="card-img-top" alt="...">-->
<!--                  <div class="card-body">-->
<!--                    <h5 class="card-title">Card title</h5>-->
<!--                    <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>-->
<!--                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card">-->
<!--                  <img src="..." class="card-img-top" alt="...">-->
<!--                  <div class="card-body">-->
<!--                    <h5 class="card-title">Card title</h5>-->
<!--                    <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>-->
<!--                    <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->


<!--              <div class="tab-pane active" id="pricing-two-mnthly" role="tabpanel" aria-labelledby="pricing-two-mnthly-tab">-->
<!--                <div class="row">-->
<!--                  <div class="offset-1 col-lg-5 mb-4">-->
<!--                    <div class="card border-0 card-shadow h-100">-->
<!--                      <div class="card-body p-4 p-xl-5">-->
<!--                        <h4 class="mb-3">Development</h4>-->
<!--                        <div class="d-flex align-items-center">-->
<!--                          <h3 class="mb-0 mr-auto font-weight-bold">Free</h3>-->
<!--                        </div>-->
<!--                        <h6 class="mb-4">Monthly</h6>-->
<!--                        <button class="btn btn-indigo btn-block mb-4" [disabled]="loading || portalConfig.config$.org?.plan != 'paid'" >-->
<!--                          {{portalConfig.config$.org?.plan != 'paid' ? 'Current' : 'Change Plan'}}-->
<!--                        </button>-->
<!--                        <ul class="list-arrow mb-40px">-->
<!--                          <li>Test mode only</li>-->
<!--                          <li>Sandbox EHR Integrations</li>-->
<!--                          <li>No live Patient Data</li>-->
<!--                          <li>Email Support</li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-lg-5 mb-4">-->
<!--                    <div class="card border-0 card-shadow h-100">-->
<!--                      <div class="card-body p-4 p-xl-5">-->
<!--                        <h4 class="mb-3">Live</h4>-->
<!--                        <div class="d-flex align-items-center">-->
<!--                          <h3 class="mb-0 mr-auto font-weight-bold">$100.00</h3>-->
<!--                        </div>-->
<!--                        <h6 class="mb-4">Minimum Monthly</h6>-->
<!--                        <button [disabled]="loading || portalConfig.config$.org?.plan == 'paid'" (click)="onUpgradePlan()" class="btn btn-indigo btn-block mb-4">-->
<!--                          {{portalConfig.config$.org?.plan == 'paid' ? 'Current' : 'Upgrade Plan'}}-->

<!--                          <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
<!--                        </button>-->
<!--                        <ul class="list-arrow mb-40px">-->
<!--                          <li>Test & Live mode</li>-->
<!--                          <li>27,000+ EHR Integrations</li>-->
<!--                          <li>Live Patient Data</li>-->
<!--                          <li>Email & Live Chat Support</li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--            </div>-->
<!--          </div>-->

<!--        </div>&lt;!&ndash; row &ndash;&gt;-->

<!--        <hr class="mg-y-40">-->

<!--        <div class="mg-b-20"></div>-->

<!--      </div>&lt;!&ndash; az-profile-body &ndash;&gt;-->
<!--    </div>&lt;!&ndash; az-content-body &ndash;&gt;-->
<!--  </div>&lt;!&ndash; container &ndash;&gt;-->
<!--</div>-->

