export const environment = {
  name: "development",

  //specify the lighthouse base that we're going to use to authenticate against all our source/providers. Must not have trailing slash
  lighthouse_api_endpoint_base: 'https://lighthouse.fastenhealth.com',

  //used to specify the api server that we're going to use (can be relative or absolute). Must not have trailing slash
  // connect_api_endpoint_base: 'https://api.connect-dev.fastenhealth.com/v1',
  // if relative, must start with /
  connect_api_endpoint_base: 'https://api.connect-dev.fastenhealth.com/v1',
  connect_api_jwt_issuer_host: 'https://api.connect-dev.fastenhealth.com/v1',

  jwks_uri: 'https://cdn.fastenhealth.com/jwks/fasten-connect/dev.json',

  //Stripe pricing table (Test mode in Dev)
  stripe_customer_portal: 'https://billing.stripe.com/p/login/test_bIY7thbZL5g6gXC000'
  // stripe_customer_portal: 'https://billing.stripe.com/p/login/dR6aEYe5h4AYe2I144'
};
