import { Component, OnInit } from '@angular/core';
import {PortalConfigService} from '../../services/portal-config.service';
import {environment} from '../../../environments/environment';
import {ConnectService} from '../../services/connect.service';
import {URL_PATTERN} from '../../app.constants';
import {Organization} from '../../models/fasten/organization';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {LogoCropperComponent} from '../../components/logo-cropper/logo-cropper.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.scss']
})
export class OrgSettingsComponent implements OnInit {
  loading: boolean = false

  urlPattern = URL_PATTERN

  submitted: boolean = false
  updateOrg: Organization = new Organization()
  errorMsg: string = ""

  croppedImage: any = '';
  croppedImageEvent: ImageCroppedEvent | null = null;

  constructor(
    public portalConfig: PortalConfigService,
    public connectService: ConnectService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.portalConfig.ConfigSubject.subscribe((config) => {
      this.updateOrg = {...config.org!}
    })

  }

  updateOrgSubmit(){
    this.loading = true
    this.submitted = true;

    console.log("starting update process...",this.updateOrg)
    this.connectService.updateOrg(this.portalConfig.config$.org?.id!, this.updateOrg, this.croppedImageEvent?.blob).subscribe(
      (org) => {
        console.log("Organization Updated", org)
        this.loading = false

        //update the org in the portal config
        this.portalConfig.config = {org: org}

      },
      (err) => {
        console.error("Error updating Organization", err)
        this.errorMsg = err.error.message
        this.loading = false
      }
    )

  }

  openLogoCropper(){
    this.modalService.open(LogoCropperComponent).result.then(
      (result) => {
        this.croppedImageEvent = result
        this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(this.croppedImageEvent?.objectUrl!);
      },
      (reason) => {
        console.log(`Dismissed`, reason);
      },
    );
  }
}
