import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DevelopersComponent} from './pages/developers/developers.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AuthSigninComponent} from './pages/auth-signin/auth-signin.component';
import {AuthSignupComponent} from './pages/auth-signup/auth-signup.component';
import {AuthSignupOrganizationComponent} from './pages/auth-signup-organization/auth-signup-organization.component';
import {IsAuthenticatedAuthGuard} from './auth-guards/is-authenticated-auth-guard';
import {OrgSettingsComponent} from './pages/org-settings/org-settings.component';
import {AccountSettingsComponent} from './pages/account-settings/account-settings.component';
import {WebhookLogsComponent} from './pages/webhook-logs/webhook-logs.component';
import {OrgBillingComponent} from './pages/org-billing/org-billing.component';
import {OrgTeamComponent} from './pages/org-team/org-team.component';
import {AuthResetRequestComponent} from './pages/auth-reset-request/auth-reset-request.component';
import {AuthResetComponent} from './pages/auth-reset/auth-reset.component';

const routes: Routes = [

  { path: 'auth/signin', component: AuthSigninComponent },
  { path: 'auth/signup', component: AuthSignupComponent },
  { path: 'auth/invite', component: AuthSignupComponent },
  { path: 'auth/signup/org', component: AuthSignupOrganizationComponent },
  { path: 'auth/reset/request', component: AuthResetRequestComponent },
  { path: 'auth/reset', component: AuthResetComponent },

  { path: '', redirectTo: '/developers', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [ IsAuthenticatedAuthGuard] },
  { path: 'developers', component: DevelopersComponent, canActivate: [ IsAuthenticatedAuthGuard] },
  { path: 'developers/webhooks/:webhookId/logs', component: WebhookLogsComponent, canActivate: [ IsAuthenticatedAuthGuard] },
  { path: 'org/settings', component: OrgSettingsComponent, canActivate: [ IsAuthenticatedAuthGuard] },
  { path: 'org/billing', component: OrgBillingComponent, canActivate: [ IsAuthenticatedAuthGuard] },
  { path: 'org/team', component: OrgTeamComponent, canActivate: [ IsAuthenticatedAuthGuard] },
  { path: 'account/settings', component: AccountSettingsComponent, canActivate: [ IsAuthenticatedAuthGuard] },

  //must be at bottom of list
  { path: '**', redirectTo: 'developers' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
