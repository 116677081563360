<div class="mg-t-10 container ">
  <nav class="mg-l-auto nav nav-pills flex-column flex-md-row">
    <a class="nav-link" data-bs-toggle="tab" routerLink="/org/settings">Details</a>
    <a class="nav-link active" data-bs-toggle="tab" routerLink="/org/team">Team</a>
    <a class="nav-link" data-bs-toggle="tab"  routerLink="/org/billing">Billing</a>
  </nav>

  <div class="row">
    <div class="col-12">

      <div class="card mg-t-20">
        <h5 class="card-header d-flex justify-content-between align-items-center">
          Team Members
          <button (click)="openOrgTeamInviteModal()" class="btn btn-outline-indigo float-right text-indigo">Invite new Member</button>
        </h5>

        <div class="card-body">
          <p>Invite your colleagues to join your team.</p>
          <table class="table mg-t-10">
            <thead>
            <tr>
              <th scope="col">Status</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let member of teamMembers">
              <th scope="row">
                <span [ngClass]="{ 'badge-success': member.status === 'active', 'badge-warning': member.status === 'pending'}" class="badge">{{member.status}}</span>
              </th>
              <td>{{member.name}}</td>
              <td>{{member.email}}</td>
            </tr>
            </tbody>
          </table>

        </div>

      </div>

    </div>
  </div>


</div>
