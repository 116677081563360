import {Inject, Injectable} from '@angular/core';
import {User} from '../models/fasten/user';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ResponseWrapper} from '../models/response-wrapper';
import {BehaviorSubject} from 'rxjs';
import * as jose from 'jose'
import {PortalConfigService} from './portal-config.service';

export const FASTEN_AUTH_COOKIE_NAME = 'fasten_connect_auth'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public IsAuthenticatedSubject = new BehaviorSubject<boolean>(false)

  constructor(private _httpClient: HttpClient, private portalConfigService: PortalConfigService) {}

  /**
   * Signup  (and Signin) both require an "online" user.
   * @param newUser
   * @constructor
   */
  public async SignupWithInvite(newUser: User, inviteToken: string): Promise<any> {
    let queryParams = {
      "token": inviteToken
    }

    let resp = await this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/auth/invite`, newUser, { withCredentials: true, params:queryParams }).toPromise()
    console.log(resp)
    return resp
  }
  /**
   * Signup  (and Signin) both require an "online" user.
   * @param newUser
   * @constructor
   */
  public async Signup(newUser?: User): Promise<any> {
    let resp = await this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/auth/signup`, newUser, { withCredentials: true }).toPromise()
    console.log(resp)
    return resp
  }

  public async Signin(email: string, pass: string): Promise<any> {
    let currentUser = new User()
    currentUser.email = email
    currentUser.password = pass
    let resp = await this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/auth/login`, currentUser, { withCredentials: true }).toPromise()
    return resp
  }

  public async Signout(): Promise<any> {
    this.publishAuthenticationState(false)
    return this.deleteCookie(FASTEN_AUTH_COOKIE_NAME)
    // // let remotePouchDb = new PouchDB(this.getRemoteUserDb(localStorage.getItem("current_user")), {skip_setup: true});
    // if(this.pouchDb){
    //   await this.pouchDb.logOut()
    // }
    // await this.Close()
  }


  // User has requested a password reset. This is the first step in the process.
  public async UserResetRequest(userEmail: string): Promise<any> {
    let payload = {
      "email": userEmail
    }

    let resp = await this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/auth/reset/request`, payload, {  }).toPromise()
    console.log(resp)
    return resp
  }

  // Complete the password reset process. This is the second step in the process.
  public async UserReset(newPassword: string, inviteToken: string): Promise<any> {
    let queryParams = {
      "token": inviteToken
    }

    let payload = {
      "password": newPassword
    }

    let resp = await this._httpClient.post<ResponseWrapper>(`${environment.connect_api_endpoint_base}/auth/reset`, payload, { withCredentials: true, params:queryParams }).toPromise()
    console.log(resp)
    return resp
  }

  public async GetJWTPayload(): Promise<any> {
    let authToken = this.getCookie(FASTEN_AUTH_COOKIE_NAME)
    if (!authToken) {
      return null
    }
    let jwks = jose.createRemoteJWKSet(new URL(environment.jwks_uri))
    let issuerHost = environment.connect_api_jwt_issuer_host

    try {
      //audience and issuer must be the same. This token is only valid on the fasten connect api
      const {payload, protectedHeader} = await jose.jwtVerify(authToken, jwks, {
        issuer: issuerHost,
        audience: issuerHost,
      })
      // @ts-ignore
      this.portalConfigService.config = {user: payload}
      return payload
    } catch (e) {
      console.error("failed to verify jwt:", e, issuerHost)
      return null
    }
  }

  public async IsAuthenticated(): Promise<boolean> {
    let payload = await this.GetJWTPayload()
    let isAuthenticated = payload != null
    this.publishAuthenticationState(isAuthenticated)
    return isAuthenticated
  }

  //https://stackoverflow.com/questions/34298133/angular-cookies
  private getCookie(name: string): string {
    const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i  = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private deleteCookie(name: string) {
    this.setCookie(name, '', -99999); // - 1 was not far back enough to actually remove the cookie, this sets the time before the epoch and will cause the cookie to be deleted.
  }
  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}; SameSite=Lax`;
  }

  private publishAuthenticationState(authenticated: boolean){
    if(this.IsAuthenticatedSubject.value != authenticated){
      this.IsAuthenticatedSubject.next(authenticated)
    }
  }
}
