<div class="modal-header">
  <h4 class="modal-title" id="modal-credential-title">
    {{ editorMode == "create" ? "Create" : "Update" }} Credential
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body">
  <p>
    API Credentials are used by your application to access the Fasten Connect
    API
  </p>

  <form>
    <div class="mb-3">
      <label for="redirectUri">Redirect Url<span ngbTooltip="required" class="text-danger">*</span></label>
      <div class="input-group">
        <input
          required
          id="redirectUri"
          class="form-control"
          placeholder="https://example.com/..."
          name="redirectUri"
          type="url"
          [pattern]="urlPattern"
          [(ngModel)]="redirectUri"
          #redirectUriField="ngModel"
        />
      </div>
    </div>

    <div class="mb-3">
      <label for="note">Note</label>
      <div class="input-group">
        <input
          id="note"
          class="form-control"
          placeholder="Additional Note"
          name="note"
          type="text"
          [(ngModel)]="note"
          #noteField="ngModel"
        />
      </div>
    </div>

    <div
      *ngIf="redirectUriField.invalid && (redirectUriField.dirty || redirectUriField.touched)"
      class="alert alert-danger">
      <div *ngIf="redirectUriField.errors?.['required']">
        Redirect Url is required.
      </div>
      <div *ngIf="redirectUriField.errors?.['pattern']">
        Redirect Url must be a valid url starting with https://
      </div>
    </div>

    <div *ngIf="submitOrgCredentialError" class="alert alert-danger">
      {{ submitOrgCredentialError }}
    </div>
  </form>
</div>
<div class="modal-footer">
  <div style="display: block; width: 100%">
    <ng-container *ngIf="editorMode == 'edit'">
      <button
        type="button"
        class="float-left btn btn-outline-danger"
        (click)="deleteOrgCredential()"
      >Delete
        <span
          *ngIf="deleteOrgCredentialLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>

      <button
        type="button"
        class="float-right btn btn-outline-indigo"
        [disabled]="!(redirectUriField.dirty || noteField.dirty) || submitOrgCredentialLoading"
        (click)="updateOrgCredential()"
      >Update
        <span
          *ngIf="submitOrgCredentialLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </ng-container>

    <ng-container *ngIf="editorMode == 'create'">
      <button
        type="button"
        class="btn btn-outline-indigo"
        [disabled]="submitOrgCredentialLoading"
        (click)="createOrgCredential()"
      >Create
        <span
          *ngIf="submitOrgCredentialLoading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </ng-container>
  </div>
</div>
