import {Component, Input, OnInit} from '@angular/core';
import {Organization} from '../../models/fasten/organization';
import {ConnectService} from '../../services/connect.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PortalConfigService} from '../../services/portal-config.service';

@Component({
  selector: 'app-org-team-invite',
  templateUrl: './org-team-invite.component.html',
  styleUrls: ['./org-team-invite.component.scss']
})
export class OrgTeamInviteComponent implements OnInit {
  @Input() org: Organization | null = null

  teamMemberEmail: string = ''

  submitOrgInviteLoading: boolean = false
  submitOrgInviteError: string = ''

  constructor(
    public portalConfig: PortalConfigService,
    private connectService: ConnectService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  public sendOrgTeamInvite() {
    this.submitOrgInviteLoading = true
    this.submitOrgInviteError = ''

    if(!this.portalConfig.config$.org?.id){
      this.submitOrgInviteLoading = false
      this.submitOrgInviteError = "Unknown Organization ID"
      return
    }


    this.connectService.sendOrgInvite(this.portalConfig.config$.org?.id, this.teamMemberEmail).subscribe((result) => {
        this.submitOrgInviteLoading = false
        this.activeModal.close(true)
      }, (error: any) => {
        console.log("Error Adding Org Webhook", error)
        this.submitOrgInviteLoading = false
        this.submitOrgInviteError = JSON.stringify(error)

      })
  }
}
