import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html',
  styleUrls: ['./auth-reset.component.scss']
})
export class AuthResetComponent implements OnInit {
  password: string = ""
  confirmPassword: string = ""
  loading: boolean = false
  resetToken: string | null = ""
  errorMsg: string = ""

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);
    this.resetToken = urlParams.get('token');
  }

  onSubmit() {
    if(this.password != this.confirmPassword){
      this.errorMsg = "passwords do not match"
      return
    }

    if(!this.resetToken){
      this.errorMsg = "no reset token provided"
      return
    }

    this.loading = true

    this.authService.UserReset(this.password, this.resetToken)
      .then(() => {
        this.loading = false
        this.router.navigateByUrl('/dashboard')
      })
      .catch((err)=>{
        this.loading = false
        this.errorMsg = "an error occurred during password reset"
        console.log(err)
      })
  }

}
