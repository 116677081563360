import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-auth-reset-request',
  templateUrl: './auth-reset-request.component.html',
  styleUrls: ['./auth-reset-request.component.scss']
})
export class AuthResetRequestComponent implements OnInit {
  email: string = ''
  loading: boolean = false
  errorMsg: string = ""
  successMsg: string = ""

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.loading = true

    this.authService.UserResetRequest(this.email).then(
      (resp) => {
        console.log("User Password Reset Request Created", resp)
        this.errorMsg = ""
        this.successMsg = "Password reset request sent. Check your email for further instructions."
        this.loading = false;
      },
      (err) => {
        console.error("Error submitting password reset request", err)
        this.errorMsg = "An error occurred while submitting password reset request.\nPlease try again later or contact support for assistance."
        this.successMsg = ""
        this.loading = false
      }
    )
  }
}
