export const URL_PATTERN = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,64}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

// https://medium.com/@ojiofor/angular-reactive-forms-strong-password-validation-8dbcce92eb6c
//     Minimum Length: A strong password should have a minimum length, typically at least 8 characters.
//     Uppercase Letters: It should contain at least one uppercase letter.
//     Lowercase Letters: It should contain at least one lowercase letter.
//     Numbers: It should contain at least one number.
//     Special Characters: It should contain at least one special character (e.g., !, @, #, $).
export const STRONG_PASSWORD_PATTERN = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

export enum ApiMode {
  Live = 'live',
  Test = 'test',
}

export enum CredentialStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum WebhookStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum WebhookDeliveryStatus {
  Success = 'success',
  Failure = 'failure',
}

export enum CRUDMode {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum EventTypes{
  EventTypesPatientEHIExportFailed = "patient.ehi_export_failed",
  EventTypesPatientEHIExportSuccess = "patient.ehi_export_success",
  EventTypesPatientAuthorizationRevoked = "patient.authorization_revoked",
  EventTypesWebhookTest               = "webhook.test",
}
