
export class Organization {
  id: string = ""
  name: string = ""
  logo_uri: string = ""
  privacy_policy_uri: string = ""
  website_uri: string = ""
  terms_and_conditions_uri?: string = ""
  company_size?: string = ""
  plan?: string = ""
}
