import { Component, Input, OnInit } from '@angular/core';
import { ApiMode, URL_PATTERN } from '../../app.constants';
import { OrganizationCredential } from '../../models/fasten/organization-credential';
import { ConnectService } from '../../services/connect.service';
import { Organization } from '../../models/fasten/organization';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-org-credentials-editor',
  templateUrl: './org-credentials-editor.component.html',
  styleUrls: ['./org-credentials-editor.component.scss']
})
export class OrgCredentialsEditorComponent implements OnInit {
  urlPattern = URL_PATTERN

  @Input() editorMode: 'create' | 'edit' = 'create'
  @Input() org: Organization | null = null
  @Input() apiMode: ApiMode = ApiMode.Test
  @Input() orgCredential: OrganizationCredential | null = null //only set when editing

  submitOrgCredentialLoading: boolean = false
  deleteOrgCredentialLoading: boolean = false
  redirectUri: string = ""
  note: string = ""
  submitOrgCredentialError: string = ''


  constructor(
    private connectService: ConnectService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (this.editorMode === 'edit' && this.orgCredential) {
      this.redirectUri = this.orgCredential.redirect_uri
      this.note = this.orgCredential.note
    }
  }

  public deleteOrgCredential() {
    if (confirm("Are you sure you'd like to delete these API Credentials?") == true) {
      this.deleteOrgCredentialLoading = true

      if (!this.orgCredential?.id) {
        this.deleteOrgCredentialLoading = false
        this.submitOrgCredentialError = "Unknown Organization Credential ID"
        return
      }

      this.connectService.deleteOrgCredential(this.org!.id, this.orgCredential.id).subscribe((result) => {
        console.log("Deleted Org Credential", result)
        this.deleteOrgCredentialLoading = false
        this.activeModal.close(result)
      }, (error: any) => {
        console.log("Error Updating Org Credential", error)
        this.deleteOrgCredentialLoading = false
        this.submitOrgCredentialError = JSON.stringify(error)

      },
        () => {
          console.log("Completed Deleting Org Credential")
          this.deleteOrgCredentialLoading = false
        })

    }
  }

  public updateOrgCredential() {
    this.submitOrgCredentialLoading = true
    this.submitOrgCredentialError = ''

    if (!this.orgCredential?.id) {
      this.submitOrgCredentialLoading = false
      this.submitOrgCredentialError = "Unknown Organization Credential ID"
      return
    }
    if (this.redirectUri == this.orgCredential.redirect_uri) {
      this.submitOrgCredentialLoading = false
      this.submitOrgCredentialError = "Redirect URI has not changed"
    }


    this.connectService.updateOrgCredential(this.org!.id, this.orgCredential.id, this.redirectUri, this.note).subscribe((result) => {
      console.log("Updated Org Credential", result)
      this.submitOrgCredentialLoading = false
      this.activeModal.close(result)
    }, (error: any) => {
      console.log("Error Updating Org Credential", error)
      this.submitOrgCredentialLoading = false
      this.submitOrgCredentialError = JSON.stringify(error)

    },
      () => {
        console.log("Completed Updating Org Credential")
        this.submitOrgCredentialLoading = false
      })
  }

  public createOrgCredential() {
    this.submitOrgCredentialLoading = true
    this.submitOrgCredentialError = ''

    if (!this.org?.id) {
      this.submitOrgCredentialLoading = false
      this.submitOrgCredentialError = "Unknown Organization ID"
      return
    }


    this.connectService.createOrgCredentials(this.org.id, this.apiMode, this.redirectUri, this.note).subscribe((result) => {
      console.log("Added Org Credential", result)
      this.submitOrgCredentialLoading = false
      this.activeModal.close(result)
    }, (error: any) => {
      console.log("Error Adding Org Credential", error)
      this.submitOrgCredentialLoading = false
      this.submitOrgCredentialError = JSON.stringify(error)

    },
      () => {
        console.log("Completed Adding Org Credential")
        this.submitOrgCredentialLoading = false
      })
  }

}
