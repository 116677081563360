import { Component, OnInit } from '@angular/core';
import {PortalConfigService} from '../../services/portal-config.service';
import {ConnectService} from "../../services/connect.service";
import {STRONG_PASSWORD_PATTERN} from "../../app.constants";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  strong_password_pattern = STRONG_PASSWORD_PATTERN
  passChangeLoading: boolean = false;
  emailChangeLoading: boolean = false;
  passChangeErrorMsg: string = "";
  emailChangeErrorMsg: string = "";

  passChangeUpdate = {
    oldPass: "",
    newPass: "",
    newPassConfirm: ""
  };

  emailChangeUpdate = {
    oldEmail: this.portalConfig.config$.user?.email || "",
    newEmail: "",
    password: ""
  };

  constructor(
    public portalConfig: PortalConfigService,
    private authService: AuthService,
    private connectService: ConnectService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  passChangeSubmit():  void {
    this.passChangeLoading = true
    this.connectService.updateUser(this.passChangeUpdate.oldPass, this.portalConfig.config$.user?.email || "", "", this.passChangeUpdate.newPass).subscribe(
      (user) => {
        console.log("Password Updated", user)
        this.passChangeLoading = false
        this.passChangeErrorMsg = "";
        this.authService.Signout().then(() => {
          return this.router.navigateByUrl('/auth/signin?action=password-changed');
        }).catch((err) => {
          console.error("Error signing out after password change", err)
        });
      },
      (err) => {
        console.error("Error updating Password", err)
        this.passChangeErrorMsg = err.error.message
        this.passChangeLoading = false;
      }
    );
  }
  emailChangeSubmit(): void {
    this.emailChangeLoading = true;
    this.connectService.updateUser(this.emailChangeUpdate.password, this.emailChangeUpdate.oldEmail, this.emailChangeUpdate.newEmail, "").subscribe(
      (user) => {
        console.log("Email Updated", user)
        this.emailChangeLoading = false
        this.emailChangeErrorMsg = "";
        this.authService.Signout().then(() => {
          return this.router.navigateByUrl('/auth/signin?action=email-changed');
        }).catch((err) => {
          console.error("Error signing out after email change", err)
        });
      },
      (err) => {
        console.error("Error updating Email", err)
        this.emailChangeErrorMsg = err.error.message
        this.emailChangeLoading = false;
      }
    );

  }
}
