import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[passwordConfirmValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordConfirmValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class PasswordConfirmValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const passwordConfirm = control.get('password_confirm');

    return password && passwordConfirm && password.value !== passwordConfirm.value
      ? { password_confirm: true }
      : null;
  }
}
