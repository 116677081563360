<div class="modal-header">
  <h4 class="modal-title">Organization Logo</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <p>Fasten Connect requires a logo for display when connecting providers</p>
      <input type="file" (change)="fileChangeEvent($event)" accept=".png,.jpeg,.jpg" />

      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>

    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-indigo" [disabled]="submitLogoLoading" (click)="onSubmit()">
    Save Logo
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Close</button>
</div>
