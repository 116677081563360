import {Component, Input, OnInit} from '@angular/core';
import {ApiMode, URL_PATTERN} from '../../app.constants';
import {Organization} from '../../models/fasten/organization';
import {OrganizationCredential} from '../../models/fasten/organization-credential';
import {OrganizationWebhook} from '../../models/fasten/organization-webhook';
import {ConnectService} from '../../services/connect.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-org-webhooks-editor',
  templateUrl: './org-webhooks-editor.component.html',
  styleUrls: ['./org-webhooks-editor.component.scss']
})
export class OrgWebhooksEditorComponent implements OnInit {
  urlPattern = URL_PATTERN

  @Input() editorMode: 'create' | 'edit' = 'create'
  @Input() org: Organization | null = null
  @Input() apiMode: ApiMode = ApiMode.Test
  @Input() orgWebhook: OrganizationWebhook | null = null //only set when editing

  submitOrgWebhookLoading: boolean = false
  deleteOrgWebhookLoading: boolean = false
  endpointUri: string = ""
  submitOrgWebhookError: string = ''
  active: boolean = true

  constructor(
    private connectService: ConnectService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (this.editorMode === 'edit' && this.orgWebhook) {
      this.endpointUri = this.orgWebhook.endpoint_uri
      this.active = this.orgWebhook.status === 'active'
    }
  }

  public deleteOrgWebhook() {
    if (confirm("Are you sure you'd like to delete this Webhook?") == true) {
      this.deleteOrgWebhookLoading = true

      if(!this.orgWebhook?.id){
        this.deleteOrgWebhookLoading = false
        this.submitOrgWebhookError = "Unknown Organization Webhook ID"
        return
      }

      this.connectService.deleteOrgWebhook(this.org!.id, this.orgWebhook.id).subscribe((result) => {
          console.log("Deleted Org Webhook", result)
          this.deleteOrgWebhookLoading = false
          this.activeModal.close(result)
        }, (error: any) => {
          console.log("Error Updating Org Webhook", error)
          this.deleteOrgWebhookLoading = false
          this.submitOrgWebhookError = JSON.stringify(error)

        },
        () => {
          console.log("Completed Deleting Org Webhook")
          this.deleteOrgWebhookLoading = false
        })

    }
  }

  public updateOrgWebhook() {
    this.submitOrgWebhookLoading = true
    this.submitOrgWebhookError = ''

    if(!this.orgWebhook?.id){
      this.submitOrgWebhookLoading = false
      this.submitOrgWebhookError = "Unknown Organization Webhook ID"
      return
    }
    if(this.endpointUri == this.orgWebhook.endpoint_uri  && this.active == (this.orgWebhook.status === 'active')){
      this.submitOrgWebhookLoading = false
      this.submitOrgWebhookError = "Webhook settings have not changed"
    }

    this.connectService.updateOrgWebhook(this.org!.id, this.orgWebhook.id, this.endpointUri, this.active? 'active' : 'inactive').subscribe((result) => {
        console.log("Updated Org Webhook", result)
        this.submitOrgWebhookLoading = false
        this.activeModal.close(result)
      }, (error: any) => {
        console.log("Error Updating Org Webhook", error)
        this.submitOrgWebhookLoading = false
        this.submitOrgWebhookError = JSON.stringify(error)

      },
      () => {
        console.log("Completed Updating Org Webhook")
        this.submitOrgWebhookLoading = false
      })
  }

  public createOrgWebhook() {
    this.submitOrgWebhookLoading = true
    this.submitOrgWebhookError = ''

    if(!this.org?.id){
      this.submitOrgWebhookLoading = false
      this.submitOrgWebhookError = "Unknown Organization ID"
      return
    }


    this.connectService.createOrgWebhooks(this.org.id, this.apiMode!, this.endpointUri, this.active? 'active' : 'inactive').subscribe((result) => {
        this.submitOrgWebhookLoading = false
        this.activeModal.close(result)
      }, (error: any) => {
        console.log("Error Adding Org Webhook", error)
        this.submitOrgWebhookLoading = false
        this.submitOrgWebhookError = JSON.stringify(error)

      },
      () => {
        console.log("Completed Adding Org Webhook")
        this.submitOrgWebhookLoading = false
      })
  }

}
