import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConnectService} from '../../services/connect.service';
import {ApiMode, CRUDMode, URL_PATTERN} from '../../app.constants';
import {PortalConfigService} from '../../services/portal-config.service';
import {OrganizationCredential} from '../../models/fasten/organization-credential';
import {PortalConfig} from '../../models/portal-config';
import {OrganizationWebhook} from '../../models/fasten/organization-webhook';
import {OrgCredentialsEditorComponent} from '../../components/org-credentials-editor/org-credentials-editor.component';
import {OrgWebhooksEditorComponent} from '../../components/org-webhooks-editor/org-webhooks-editor.component';

@Component({
  selector: 'app-developers',
  templateUrl: './developers.component.html',
  styleUrls: ['./developers.component.scss']
})
export class DevelopersComponent implements OnInit {


  orgCredentials: {[name: string]: OrganizationCredential[]} = {
    [ApiMode.Live]: [],
    [ApiMode.Test]: [],
  }

  newCredential: {org_credential: OrganizationCredential, private_key: string} | null = null

  orgWebhooks: {[name: string]: OrganizationWebhook[]} = {
    [ApiMode.Live]: [],
    [ApiMode.Test]: [],
  }


  constructor(
    private connectService: ConnectService,
    private modalService: NgbModal,
    public portalConfig: PortalConfigService,
  ) { }

  ngOnInit(): void {

    this.connectService.getOrgCredentials(this.portalConfig.config$.user?.org_id!).subscribe((data) => {
      for(let cred of data){
        this.orgCredentials[cred.api_mode].push(cred)
      }
    })
    this.connectService.getOrgWebhooks(this.portalConfig.config$.user?.org_id!).subscribe((data) => {
      for(let hook of data){
        this.orgWebhooks[hook.api_mode].push(hook)
      }
    })
  }


  public showOrgCredentialEditorModal(editorMode: 'create' | 'edit', orgCredential?: OrganizationCredential) {
    let orgCredentialEditorModal = this.modalService.open(OrgCredentialsEditorComponent, { ariaLabelledBy: 'modal-basic-title' })
    orgCredentialEditorModal.componentInstance.org = this.portalConfig.config$.org
    orgCredentialEditorModal.componentInstance.apiMode = this.portalConfig.config$.apiMode!
    orgCredentialEditorModal.componentInstance.editorMode = editorMode

    if(editorMode === 'edit'){
      orgCredentialEditorModal.componentInstance.orgCredential = orgCredential
    }

    orgCredentialEditorModal.result.then(
      (result: {action: CRUDMode, org_credential: OrganizationCredential, private_key: string}) => {
        console.log(`Closed with:`, result)

        if(editorMode == 'create'){
          this.orgCredentials[result.org_credential.api_mode].push(result.org_credential)
          this.newCredential = result
        } else {
          //find the credential and update it or delete it
          let apiMode = result.org_credential.api_mode
          for(let i = 0; i < this.orgCredentials[apiMode].length; i++){
            if(this.orgCredentials[apiMode][i].id == result.org_credential.id){
              if(result.action == CRUDMode.Delete){
                this.orgCredentials[apiMode].splice(i, 1)
                break
              } else {
                //update the credential
                this.orgCredentials[apiMode][i] = result.org_credential
                break
              }
            }
          }
        }
      },
      (reason) => {
        console.log(`Dismissed ${reason}`)
      },
    );
  }

  public copyPublicId(credential: OrganizationCredential) {
    navigator.clipboard.writeText(credential.id);
  }


  public showOrgWebhookEditorModal(editorMode: 'create' | 'edit', orgWebhook?: OrganizationWebhook) {
    let orgWebhookEditorModal = this.modalService.open(OrgWebhooksEditorComponent, { ariaLabelledBy: 'modal-basic-title' })
    orgWebhookEditorModal.componentInstance.org = this.portalConfig.config$.org
    orgWebhookEditorModal.componentInstance.apiMode = this.portalConfig.config$.apiMode!
    orgWebhookEditorModal.componentInstance.editorMode = editorMode

    if(editorMode === 'edit'){
      orgWebhookEditorModal.componentInstance.orgWebhook = orgWebhook
    }
    orgWebhookEditorModal.result.then(
      (result: {action: CRUDMode, org_webhook: OrganizationWebhook}) => {
        console.log(`Closed with:`, result)

        let apiMode = result.org_webhook.api_mode
        if(editorMode == 'create'){
          this.orgWebhooks[apiMode].push(result.org_webhook)
        } else {
          apiMode = orgWebhook?.api_mode!
          //find the webhook and update it
          for(let i = 0; i < this.orgWebhooks[apiMode].length; i++){
            if(this.orgWebhooks[apiMode][i].id == result.org_webhook.id){
              if(result.action == CRUDMode.Delete){
                this.orgWebhooks[apiMode].splice(i, 1)
                break
              } else {
                //update the webhook
                this.orgWebhooks[apiMode][i] = result.org_webhook
                break
              }
            }
          }
        }
      },
      (reason) => {
        console.log(`Dismissed ${reason}`)
      },
    );
  }

}
